import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  testimonalTab: number;
  youtubeUrl: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      testimonalTab: 0,
      youtubeUrl: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

        if (apiRequestCallId === this.getYoutubeLinkApiCallId) {
          if (responseJson) {
             this.setState({youtubeUrl: this.getEmbedUrl(responseJson.data?.embeded_url) ?? ""}) 
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getYoutubeLinkApiCallId = "";

  async componentDidMount() {
    this.callGetYoutubeLink();
  }

  getEmbedUrl(iframeString: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(iframeString, 'text/html');
    
    const iframe = doc.querySelector('iframe');
    
    return iframe ? iframe.getAttribute('src') : "";
}


  callGetYoutubeLink() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getYoutubeLinkApiCallId = getMsg.messageId;

    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getYTBLinkURL
    );

    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getYTBLinkMethod
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({testimonalTab: newValue});
  };

  onSignIn = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  contact = () => {
    this.props.navigation.navigate("Contactus")
  }
  // Customizable Area End
}
