import React from 'react';

// Customizable Area Start
import {
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  Typography,
  TextField,
  Pagination,
  Stack,
  RadioGroup,
  Modal,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  styled,
} from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { ic_admin, ic_back, ic_pending, ic_plus, ic_success, ic_success_delete, ic_user } from './assets';
import Layout from '../../../components/src/Layout.web';
import  {
  EModalType,
} from './AccountGroupsController';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "*": { fontFamily: "Inter, sans-serif" },

  "& *": {
    boxSizing: "border-box",
    fontFamily: "Inter, sans-serif"
  },
  '.header-container': {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    justifyContent: "space-between",
    backgroundColor: "#E9E9EA",
    marginTop: "20px",
  },
  '.header-top': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: "20px",
    flexDirection: "row",
    gap: "20px"
  },
  '.header-actions': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  '.table-container': {
    maxHeight: 600,
    overflow: 'hidden',
  },
  '.filter-button': {
    padding: theme.spacing(1),
    width: "96px",
    height: "38px",
    borderBottom: "1px solid #C4C4C6",
    backgroundColor: "white",
    borderRadius: 0, 
    gap: "10px"
  },
}));
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from './AccountGroupsController';

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CreateUserContent = () => <>
  {(this.state.modalType === EModalType.CONFIRM_CREATE_USER || this.isTest) && 
   <>
        <div style={{display: "flex", flexDirection: "column"}}>
        <Typography style={{fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0}}>Create New User Form</Typography>
        </div>

       <div style={{
         display: "flex",
         flexDirection: "column",
         flex: 1,
         alignItems: "center",
         justifyContent: "center",
       }}>
        <Typography style={{textAlign:"center", fontSize: "30px", fontWeight: 700, color: "#00184C", margin: 0, paddingInline: "85px"}}>You are adding a new user with:</Typography>
        
        <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Name: <b>{`${this.state.firstName} ${this.state.lastName}`}</b></Typography>
        <Typography style={{textAlign:"center",fontWeight: 400, fontSize: "16px", color: "#00184C", margin: 0}}>Email: <b>{`${this.state.email}`}</b></Typography>
        <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Role:</Typography>
        <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 700, color: "#00184C", margin: 0}}>{this.state.role}</Typography>
        </div>
            <StyledButtonGroup>
                 <Button className='cancel-btn' variant="outlined" onClick={this.handleCloseModal}>
                    Cancel
                 </Button>
                 <Button className='create-btn' variant="contained" color="primary" onClick={() => this.handleCreateUser()}>
                    Create Employee
                 </Button>
        </StyledButtonGroup>
   </>
   }
        {(this.state.modalType === EModalType.SUCCESS_CREATE_USER || this.isTest) && 
   <>
        <div style={{display: "flex", flexDirection: "column"}}>
        <Typography style={{fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0}}>Create New User Form</Typography>
        </div>

       <div style={{
         display: "flex",
         flexDirection: "column",
         flex: 1,
         alignItems: "center",
         justifyContent: "center",
       }}>
        <Typography style={{textAlign:"center", fontSize: "30px", fontWeight: 700, color: "#00184C", margin: 0, width: "337px"}}>The new user has been successfully created</Typography>
        <Typography style={{width: "300px", textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>The user <b>{`${this.state.firstName} ${this.state.lastName}`}</b> is now available.</Typography>
       <img src={ic_success.default} style={{width: "150px", marginTop: "55px", aspectRatio: 1.05}} />
        
        </div>
            <StyledButtonGroup style={{justifyContent:"center"}}>
                 <Button className='create-btn' variant="contained" color="primary" onClick={() => {this.handleCloseModal(); this.setState({isToastOpen: true, toastContent: `You have successfully sent the invitation to the user <b>${this.state.firstName}</b>` })}}>
                    Continue
                 </Button>
        </StyledButtonGroup>
   </>
   }
   </>
   
   ButtonGroupContent = () => <StyledButtonGroup>
   {!this.state.editMode ?
     <>
       <Button className='cancel-btn' variant="outlined" onClick={this.handleCloseModal}>
         Cancel
       </Button>
       <Button className='create-btn' variant="contained" color="primary" onClick={this.createEmployee()}>
         Create Employee
       </Button>
     </>
     : <>
       <Button className='cancel-btn' variant="outlined" onClick={this.handleCloseModal}>
         Cancel
       </Button>
       <Button className='update-btn' variant="contained" color="primary"  onClick={this.createEmployee(true)}>
         Update
       </Button>
       <Button className='delete-btn' variant="contained" color="primary" onClick={() => this.setState({modalType: EModalType.CONFIRM_DELETE_USER})}>
         Delete
       </Button>
     </>
   }
</StyledButtonGroup>

RoleSelector = () =>  <StyledRoleSelector>
<Typography style={{fontSize: "14px", fontWeight: 700, color: "#334155" }}>Select the user’s role</Typography>
<div style={{display: "flex", flexDirection: "row", alignItems:"center", gap: "20px"}}>
  <Button data-test-id="adminBtn" className={this.state.role === "Administrator" ? "role-btn-active" : "role-btn"} onClick={() => this.handleRoleChange("Administrator")}>
    <img src={ic_admin.default} style={{width: "24px", height: "24px",  filter: this.state.role === "Administrator" ? "brightness(0) invert(1)" : undefined}} />
    <Typography style={{fontSize: "16px", fontWeight: 400,  color: this.state.role !== "Administrator" ? "#0F172A" : "white" }}>Administrator</Typography>
  </Button>

  <Button data-test-id="userBtn" className={this.state.role === "Regular User" ? "role-btn-active" : "role-btn"} onClick={() => this.handleRoleChange("Regular User")}>
    <img src={ic_user.default} style={{width: "24px", height: "24px", filter: this.state.role === "Regular User" ? "brightness(0) invert(1)" : undefined }} />
    <Typography style={{fontSize: "16px", fontWeight: 400, color: this.state.role !== "Regular User" ? "#0F172A" : "white" }}>Regular User</Typography>
  </Button>
  </div>
  {this.state.roleError && <Typography style={{fontSize: "14px", marginTop: "-3px", fontWeight: 400, color: "#DC2626", textAlign: "end" }}>{this.state.roleError}</Typography>}
</StyledRoleSelector>


  InitContent = () => <>
   {(this.state.modalType === EModalType.INITIAL || this.isTest) && 
          <>
               <div style={{display: "flex", flexDirection: "column"}}>
               <Typography style={{fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0}}>{!this.state.editMode ? "Account Creation Form" : "Account Edit Form" }</Typography>
               <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "16px" }}>Required fields</Typography>
               </div>
               <Box>
               <Typography style={{fontSize: "14px", fontWeight: 700, color: "#334155" }}>First Name</Typography>
               <TextField InputProps={{ endAdornment: this.state.firstNameError ? <CloseRoundedIcon style={{ color: "red", width: "24px", height: "24px" }} /> : null }}
 value={this.state.firstName} onChange={this.handleFirstNameChange} className="textField" placeholder="Enter your first name" variant="outlined" fullWidth />
               {this.state.firstNameError && <Typography style={{marginTop:"5px", fontSize: "14px", fontWeight: 400, color: "#DC2626", textAlign: "end" }}>{this.state.firstNameError}</Typography>}
               </Box>
               <Box>
               <Typography style={{fontSize: "14px", fontWeight: 700, color: "#334155" }}>Last Name</Typography>
               <TextField InputProps={{ endAdornment: this.state.lastNameError ? <CloseRoundedIcon style={{ color: "red", width: "24px", height: "24px" }} /> : null }}  value={this.state.lastName} onChange={this.handleLastNameChange} className="textField" placeholder="Enter your last name" variant="outlined" fullWidth />
               {this.state.lastNameError && <Typography style={{fontSize: "14px", marginTop:"5px", fontWeight: 400, color: "#DC2626", textAlign: "end" }}>{this.state.lastNameError}</Typography>}
               </Box>
               <Box>
               <Typography style={{fontSize: "14px", fontWeight: 700, color: "#334155" }}>Email</Typography>
               <TextField InputProps={{ endAdornment: this.state.emailError ? <CloseRoundedIcon style={{ color: "red", width: "24px", height: "24px" }} /> : null }} value={this.state.email} onChange={this.handleEmailChange} className="textField" placeholder="Enter your email" variant="outlined" fullWidth />
               {this.state.emailError && <Typography style={{fontSize: "14px", marginTop:"5px", fontWeight: 400, color: "#DC2626", textAlign: "end" }}>{this.state.emailError}</Typography>}
               </Box>
       
                  {this.RoleSelector()}
                   {this.ButtonGroupContent()}
          </>
          }
          </>

 DeleteUserContent = () => <>
     {(this.state.modalType === EModalType.CONFIRM_DELETE_USER || this.isTest) && 
   <>
        <div style={{display: "flex", flexDirection: "column"}}>
        <Typography style={{fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0}}>Account Edit Form</Typography>
        </div>

       <div style={{
         display: "flex",
         flexDirection: "column",
         flex: 1,
         alignItems: "center",
         justifyContent: "center",
       }}>
        <Typography style={{textAlign:"center", fontSize: "30px", fontWeight: 700, color: "#00184C", margin: 0, width: "462px"}}>Are you sure you want to delete {`${this.state.firstName} ${this.state.lastName}`} account?</Typography>
        <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>This action is irreversible, and all financial record associated with this account will be permanently removed.</Typography>
        </div>
            <StyledButtonGroup>
                 <Button className='cancel-btn' variant="outlined" onClick={this.handleCloseModal}>
                    Retain Account
                 </Button>
                 <Button className='delete-btn' variant="contained" color="primary" onClick={() => this.deleteUser()}>
                    Delete Employee
                 </Button>
        </StyledButtonGroup>
   </>
   }

{(this.state.modalType === EModalType.SUCCESS_DELETE_USER || this.isTest) && 
   <>
        <div style={{display: "flex", flexDirection: "column"}}>
        <Typography style={{fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0}}>Account Edit Form</Typography>
        </div>

       <div style={{
         display: "flex",
         flexDirection: "column",
         flex: 1,
         alignItems: "center",
         justifyContent: "center",
       }}>
        <Typography style={{textAlign:"center", fontSize: "30px", fontWeight: 700, color: "#00184C", margin: 0, width: "337px"}}>It's done</Typography>
        <Typography style={{width: "300px", textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>The user <b>{`${this.state.firstName} ${this.state.lastName}`}</b> has been successfully deleted.</Typography>
       <img src={ic_success_delete.default} style={{width: "150px", marginTop: "75px", aspectRatio: 0.97}} />
        
        </div>
            <StyledButtonGroup style={{justifyContent:"center"}}>
                 <Button className='create-btn' variant="contained" color="primary" onClick={this.handleCloseModal}>
                    Continue
                 </Button>
        </StyledButtonGroup>
   </>
   }
   </>

 EditUserContent = () => <>
     {(this.state.modalType === EModalType.CONFIRM_EDIT_USER || this.isTest) && 
   <>
        <div style={{display: "flex", flexDirection: "column"}}>
        <Typography style={{fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0}}>Account Edit Form</Typography>
        </div>

       <div style={{
         display: "flex",
         flexDirection: "column",
         flex: 1,
         alignItems: "center",
         justifyContent: "center",
         gap: "5px"
       }}>
        <Typography style={{textAlign:"center", fontSize: "30px", fontWeight: 700, color: "#00184C", margin: 0, width: "320px"}}>Are you sure to make these changes</Typography>
       {((this.state.firstName !== this.state.editInfo?.attributes?.first_name) || (this.state?.lastName !== this.state.editInfo?.attributes?.last_name)) && <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Name: {`${this.state.editInfo?.attributes?.first_name} ${this.state.editInfo?.attributes?.last_name} >`} <b>{`${this.state.firstName} ${this.state.lastName}`}</b></Typography>}               
       {this.state.email !== this.state.editInfo?.attributes?.email && <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Email: {`${this.state.editInfo?.attributes?.email} >`} <b>{`${this.state.email}`}</b></Typography>}               
       {this.state.role !== this.state.editInfo?.attributes?.role && 
       <>
         <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Role:</Typography>
         <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>{`${this.state.editInfo?.attributes?.role} >`} <b>{this.state?.role}</b></Typography>
       </>
       }
       
        </div>
            <StyledButtonGroup>
                 <Button className='cancel-btn' variant="outlined" onClick={this.handleCloseModal}>
                    Cancel
                 </Button>
                 <Button className='create-btn' variant="contained" color="primary" onClick={() => this.handleUpdateUser()}>
                    Update User
                 </Button>
        </StyledButtonGroup>
   </>
   }


{(this.state.modalType === EModalType.SUCCESS_EDIT_USER || this.isTest) && 
   <>
        <div style={{display: "flex", flexDirection: "column"}}>
        <Typography style={{fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0}}>Account Edit Form</Typography>
        </div>

       <div style={{
         display: "flex",
         flexDirection: "column",
         flex: 1,
         alignItems: "center",
         justifyContent: "center",
       }}>
        <Typography style={{textAlign:"center", fontSize: "30px", fontWeight: 700, color: "#00184C", margin: 0, width: "216px"}}>You made these changes</Typography>

         {this.state.firstName !== this.state.editInfo?.attributes?.first_name || this.state.lastName !== this.state.editInfo?.attributes?.last_name && <Typography style={{ textAlign: "center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0 }}>Name: {`${this.state.editInfo?.attributes?.first_name} ${this.state.editInfo?.attributes?.last_name} >`} <b>{`${this.state.firstName} ${this.state.lastName}`}</b></Typography>}
         {this.state.email !== this.state.editInfo?.attributes?.email && <Typography style={{ textAlign: "center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0 }}>Email: {`${this.state.editInfo?.attributes?.email} >`} <b>{`${this.state.email}`}</b></Typography>}
         {this.state.role !== this.state.editInfo?.attributes?.role &&
           <>
             <Typography style={{ textAlign: "center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0 }}>Role:</Typography>
             <Typography style={{ textAlign: "center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0 }}>{`${this.state.editInfo?.attributes?.role} >`} <b>{this.state.role}</b></Typography>
           </>
         }
        <img src={ic_success.default} style={{width: "150px", marginTop: "75px", aspectRatio: 1.05}} />
        
        </div>
            <StyledButtonGroup style={{justifyContent:"center"}}>
                 <Button className='create-btn' variant="contained" color="primary" onClick={this.handleCloseModal}>
                    Continue
                 </Button>
        </StyledButtonGroup>
   </>
   }</>
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <Layout navigation={this.props.navigation} currentRoute='Manage Users'>
      <StyledContainer>
        {/* Header Section */}
        <Box className="header-container">
          <img src={ic_back.default} style={{width: "28px", height: "28px", position: "absolute", top: "30px"}} />
          {/* Title and Description */}
          <Box style={{display: "flex", flexDirection: "column", marginLeft: "80px"}}>
          <Typography variant="h5" fontWeight="bold" style={{
            color: "#00184C",
            fontSize: "24px",
            
          }}>
            Manage Users
          </Typography>
          <Typography style={{
            color: "#00184C",
            fontSize: "16px",
            fontWeight: "400"
          }} variant="body2" color="textSecondary">
            You can manage your users, create new ones, edit or delete them.
          </Typography>
          </Box>
          {/* Actions (Add User, Filters, Search) */}
          <Box className="header-top">
            <Button
              style={{
                background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
                color: "#FFFFFF",
                borderRadius: "26px",
                padding: "10px 16px",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                textTransform: "capitalize",
                whiteSpace: "nowrap",
              }}
              variant="contained"
              color="primary"
              onClick={this.showAddModal}
            >
              <img src={ic_plus.default} style={{width: "24px", height: "24px"}} />
              Add User
            </Button>
            <IconButton className="filter-button" aria-label="filter">
            <FilterListIcon style={{ color: '#0F172A' }} onClick={() => this.setState({isToastOpen: true})}/>
            <span style={{color: "#75809B", fontSize: "14px", fontWeight: 700}}>Filters</span>
            </IconButton>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #E2E8F0',
                  borderRadius: '0px',
                  padding: '4px 8px',
                  width: '250px',
                  backgroundColor: "white",
                  height: "38px",
                  boxSizing: "border-box",
                  borderBottom: "1px solid #C4C4C6",
                }}
              >
              <SearchIcon sx={{ color: '#0F172A', marginRight: '8px' }} />
               <TextField
                  style={{height: "100%"}}
                  placeholder="Search"
                  size="small"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: '14px',
                       height: "100%",   
                      fontFamily: "Inter, sans-serif",
                      color: '#00184C', 
                      fontWeight: 700,
                  },
                  }}
                  value={this.state.searchText}
                  onChange={this.handleSearchText}
                  fullWidth
                />
              </Box>
          </Box>
        </Box>

        {/* Table Section */}
        <Paper sx={{ display: "flex", flex: 1, padding: 0, boxShadow: "0px 2px 8px 0px #00000014", marginTop: "10px" }}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{overflow:"hidden"}}>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Role</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell style={{opacity: 0, borderRadius: 0}}>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.groupList.slice( (this.state.currentPage - 1) * 12, (this.state.currentPage - 1) * 12 + 12).map((group: any) => (
              <TableRow key={group.id} hover>
                <StyledTableCell>{`${group.attributes.first_name} ${group.attributes.last_name}`}</StyledTableCell>
                <StyledTableCell>{group.attributes.email}</StyledTableCell>
                <StyledTableCell>{group.attributes.role}</StyledTableCell>
                <StyledTableCell style={{
                    alignItems: "center",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "100%"
                }}>
                  {group.attributes.status === "Pending" ? 
                  <PendingButton onClick={this.resendInvitation(group.id, group.attributes.first_name)}>Resend request <img src={ic_pending.default} style={{width: "18px", height: "18px",}} /></PendingButton>  
                  :
                  <StatusButton>
                  Active
                </StatusButton>
                }
                </StyledTableCell>
                <StyledTableCell>
                  <EditButton onClick={() => this.handleEditInfo(group)}>Edit</EditButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
      </StyledContainer>
      <Stack
      direction="row"
      alignItems="flex-end"
      justifyContent="space-between"
      sx={{ marginTop: 2, height: "100%", marginBottom: "40px" }}
    >
      <div style={{width: "120px", height: "1px"}} />
      {/* Pagination */}
      <StyledPagination
        count={Math.ceil(this.state.groupList.length / 12)}
        page={this.state.currentPage}
        shape="rounded"
        siblingCount={0} 
        boundaryCount={1} 
        onChange={this.handlePageChange}
      />

      {/* Results Summary */}
      <ResultsSummary>
        {this.getResultsSummary()}
      </ResultsSummary>
    </Stack>   

    <Modal
     slotProps={{
      backdrop: {
        sx: {
          backgroundColor: '#00184CB2', 
          backdropFilter: 'blur(10px)', // Blur effect
          transition: 'all 0.5s ease', // Smooth animation
          marginLeft: "234px"
        },
      },
    }}
    open={this.state.isVisibleModal} onClose={this.handleCloseModal}>
      <StyledBox>
        {this.InitContent()}
  
        {this.CreateUserContent()}
        
        {this.DeleteUserContent()}

        {this.EditUserContent()}

      <img onClick={this.onModalBack} src={ic_back.default} style={{cursor:"pointer", width: "28px", height: "28px", position: "absolute", top: "20px", left: "15px"}} />
      </StyledBox>
    </Modal>

    <Snackbar
      open={this.state.isToastOpen}
      autoHideDuration={5000} 
      onClose={this.handleCloseToast}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
    >
      <Alert
        onClose={this.handleCloseToast}
        icon={false}
        style={{
          backgroundColor: "#E6FAF0", 
          color: "#000000",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderLeft: "4px solid #4CAF50",
        }}
        action={
          <IconButton size="small" aria-label="close" onClick={this.handleCloseToast}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        }
      >
        <div contentEditable='true' dangerouslySetInnerHTML={{ __html: this.state.toastContent }}></div>
      </Alert>
    </Snackbar>
    </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: 'calc(50% + 234px / 2)', // Adjusting for the sidebar width
  transform: 'translate(-50%, -50%)',
  width: "580px",
  boxShadow: theme.shadows[5],
  padding: "25px",
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  background: "linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(235, 235, 235, 0.9) 100%)",
  border: "3px solid #FFFFFFE5",
  borderRadius: "16px",
  paddingInline: "60px",
  boxSizing: "border-box",
  minHeight: "650px",

  "@media (max-height: 720px)": {
    minHeight: "unset",
    scale: 0.85,
    marginTop: "-50px",
    marginLeft: "-32px",
    padding: "20px",
    paddingInline: "60px",
  },

  "& .requireField": {
    marginBottom: "32px",
    
    "@media (max-height: 720px)": {
      marginBottom: "0px",
    },
  },

  "& .cancel-btn": {
    border: "1px solid #00184C",
    display: "flex",
    flex: 1,
    borderRadius: "24px",
    textTransform: "capitalize",
    color: "#00184C",
    height: "44px",
    fontWeight: 700,
    maxWidth: "210px"
  },

  "& .create-btn": {
    border: "1px solid #A4DFFF",
    display: "flex",
    flex: 1,
    background:" #00184C",
    borderRadius: "24px",
    textTransform: "capitalize",
    color: "white",
    height: "44px",
    fontWeight: 700,
    maxWidth: "210px"
  },

  "& .update-btn": {
    border: "1px solid #A4DFFF",
    display: "flex",
    flex: 1,
    borderRadius: "24px",
    textTransform: "capitalize",
    color: "white",
    height: "44px",
    background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
    fontWeight: 700,
    maxWidth: "210px"
  },

  "& .delete-btn": {
    border: "1px solid #7F1D1D",
    display: "flex",
    flex: 1,
    borderRadius: "24px",
    textTransform: "capitalize",
    color: "white",
    height: "44px",
    background: "linear-gradient(122.97deg, #FFA0A0 -55.47%, #4C0000 40.61%)",
    fontWeight: 700,
    maxWidth: "210px"
  },

  "& .textField": {
    border: "1px solid #334155",
    backgroundColor: "white",
    borderRadius: "8px", 
    marginTop: "10px",

    "@media (max-height: 720px)": {
      "& input": { padding: "12px 14px" }
    },
  },

  "& .role-btn": {
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid #CBD5E1",
    background: "linear-gradient(180deg, #FFFFFF 0%, #C1C1C1 100%)",
    textTransform: "capitalize",
    borderRadius: "8px",
    gap: "10px"
  },

  "& .role-btn-active": {
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid #CBD5E1",
    background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
    textTransform: "capitalize",
    borderRadius: "8px",
    gap: "10px",
    color: "white"
  }  
}));

const StyledButtonGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(4),
  gap: "20px",
  marginBottom: "20px",

  "@media (max-height: 720px)": {
    gap: "15px",
    marginBottom: "15px",
    marginTop: theme.spacing(2),
  },
}));

const StyledRoleSelector = styled(RadioGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPagination-ul": {
    justifyContent: "center", // Align pagination to the left
  },
  "& .MuiPaginationItem-root": {
    color:"#00184C",
    fontWeight: 400,
    "&.Mui-selected": {
      color:"#00184C",
      borderRadius: "50%",
      backgroundColor: "transparent",
      fontWeight: 700,
    },
  },
}));

const ResultsSummary = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "14px",
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: "white",
  color: "#22223E",
  textAlign: "center",
  borderBottom: "none",
  borderTop: "1px solid #E9E9EA",
}));

const StatusButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#D1FAE5",
  color:"#059669" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
  "&:hover": {
    backgroundColor:"#c5f0d0",
  },
}));

const PendingButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#FEF3C7",
  color:"#D97706" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
  gap: "8px",
  boxShadow: "0px 2px 2.5px 0px #00000040",
  fontSize: "12px",
  display: "flex",
  alignItems: "center",
  paddingTop: "5px"
}));

const EditButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  backgroundColor: "#fff",
  color: "#00184C",
  border: "1px solid #00184C",
  borderRadius: "8px",
  padding: "4px 8px",
  fontWeight: "bold",
  width: "75px",

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));
// Customizable Area End
