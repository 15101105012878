import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  token: string;

  formValues: any;
  error: any;
  emailvalidation: any;
  mobilevalidation: any;
  required: any;
  open: any;
  countryList: any;
  formIsValid: any,
  accountId:any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  getcontactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  getProfileCallId:any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),

    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";
    this.getcontactUsApiCallId = "";
    this.getProfileCallId = "";

    this.state = {


      token: "",

      formValues: {
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        country: '',
        phone: '',
        topic: '',
        message: '',
      },
      countryList: [],
      error: false,
      formIsValid: false,
      open: false,
      emailvalidation: false,
      mobilevalidation: false,
      required: false,
      accountId:""


    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });

    }
    getStorageData("id").then((id) => this.setState({accountId: id}))
    
    getStorageData("token").then((token) => {this.getProfile(token); this.setState({token: token})});
    // Customizable Area End
  }



  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage),
    );
    this.send(msg);
  };

  validateEmail=(email:any)=>{
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email)
  }
  validatePhone=(phone:any)=>{
    const phoneRegex = /^\d{10,15}$/;

    return phoneRegex.test(phone)
  }


  getProfile = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfile}?account_id=${this.state.accountId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleSubmit = () => {


    let header;

    let httpBody;
    if (this.state.token) {
      if (!this.state.formValues.firstName || !this.state.formValues.lastName || !this.state.formValues.companyName || !this.state.formValues.email || !this.state.formValues.message) {
        this.setState({ required: true });
        return;
      }
      let token = this.state.token;
      header = {
        "Content-Type": "application/json",
        token
      };
      httpBody = {
        "contact_us_form": {
          "firstname": this.state.formValues.firstName,
          "lastname": this.state.formValues.lastName,
          "companyname": this.state.formValues.companyName,
          "email": this.state.formValues.email,
          "description": this.state.formValues.message,

        }
      }
    } else {
      if (!this.state.formValues.firstName || !this.state.formValues.lastName || !this.state.formValues.companyName || !this.state.formValues.email || !this.state.formValues.message) {
        this.setState({ required: true });
        return;
      }
     
      header = {
        "Content-Type": "application/json",

      };
      httpBody = {
        "contact_us_form": {
          "firstname": this.state.formValues.firstName,
          "lastname": this.state.formValues.lastName,
          "companyname": this.state.formValues.companyName,
          "email": this.state.formValues.email,
          "description": this.state.formValues.message,
          "country": this.state.formValues.country,
          "topic": this.state.formValues.topic,
          "full_phone_number": this.state.formValues.phone
        }
      }
    }

if(this.state.formIsValid){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.contactUsApiCallId = requestMessage.messageId;
    if (this.state.token) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.ContactUsAPiPostEndPoint
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.ContactUsAPiPreEndPoint
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) == message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      getStorageData("token").then((token) => {
        this.setState({ token: token });

      })


    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.contactUsApiCallId) {
      if (responseJson && responseJson.data) {
       

          this.setState({ open: true });
         
        }else{
          const errorResponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.setState({ error: true });
        }
        }else if (apiRequestCallId === this.getProfileCallId) {
          if (responseJson && responseJson.data) {
            this.setState((prevState) => ({
              formValues: {
                ...prevState.formValues, // Spread the existing formValues
                firstName: responseJson.data.attributes.first_name,
                lastName: responseJson.data.attributes.last_name,
                email:responseJson.data.attributes.email,
                companyName:responseJson.data.attributes?.organization?.company_name || "Silenium"
              
              },
            }));
          }
        }

      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if(this.state.token){
       this.setState({ error: true });
        }
        this.parseApiCatchErrorResponse(errorResponse);
      }
    

    // Customizable Area End
  }

  // Customizable Area Start
  onSignIn = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  //Customizable Area End
}