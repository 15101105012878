// Customizable Area Start
import React from "react";
import { Box, Typography, Button, styled, Tabs, Tab } from "@mui/material";
import LandingPageController, {
    Props,
} from "./LandingPageController";
import { analyticImg, bg, linkedln, linkedlnBanner, linkedlnWhite, logo, logoWhite, silenium, testimonalBg } from "./assets";

export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Container>
                {/* Header Section */}
                <Header>
                    <img src={logo.default} style={{ width: "142px", height: "32px" }} />
                    <NavLinks>
                        <Typography style={webStyle.navLink}>About Us</Typography>
                        <Typography style={webStyle.navLink}>Pricing</Typography>
                        <Typography style={webStyle.navLink} onClick={this.contact}>Contact Us</Typography>
                    </NavLinks>

                    <Button data-test-id="signInBtn" onClick={this.onSignIn} variant="outlined" style={webStyle.signupButton as any}>Signup/Login</Button>
                </Header>

                {/* Hero Section */}
                <HeroSection>
                    <Typography variant="h3" style={webStyle.heroTitle}>Let's grow together</Typography>
                    <Typography variant="body1" style={webStyle.heroDescription}>
                        Learn about our tool and how it can<br></br>help your business grow.
                    </Typography>
                    <Button variant="contained" style={webStyle.demoButton as any}>Book a Demo</Button>
                </HeroSection>

                {/* Features Section */}
                <FeaturesSection>
                    <Typography variant="h5" style={webStyle.sectionTitle}>Know our features</Typography>
                    <Typography variant="body2" style={webStyle.sectionDescription} data-test-id="textTest">
                        These are some of our main features
                    </Typography>
                    <FeatureCards>
                        {[...Array(4)].map((_, index) => (
                            <FeatureCard key={index}>
                                <Typography style={{ fontSize: "14px", fontWeight: 700 }} variant="h6">NAME FEATURE</Typography>
                                <Typography style={{ fontSize: "24px", fontWeight: 700, textAlign: "start", lineHeight: 1.3, marginTop: "5px" }}>Short description about the feature</Typography>
                                <Typography style={{ fontSize: "20px", fontWeight: 700, textAlign: "center", position: "absolute", top: "50%", left: "calc((100% - 153px)/2)"}}>PLACEHOLDER</Typography>
                            </FeatureCard>
                        ))}
                    </FeatureCards>
                </FeaturesSection>

                {/* Video Section */}
                <VideoSection>
                    {this.state.youtubeUrl && 
                         <iframe width="100%" height="100%"
                         src={this.state.youtubeUrl}>
                     </iframe>}
                </VideoSection>

                {/* Testimonal */}
                <TestimonalSection>
                    <CustomTabs value={this.state.testimonalTab} onChange={this.handleTabChange} centered>
                        <CustomTab label="Analytics" />
                        <CustomTab label="Testimonials" />
                    </CustomTabs>
                    <img src={silenium.default} style={{position: "absolute", left: "5vw", bottom: "0px", width: "90vw", aspectRatio: 7.2, objectFit:"cover" }} />
                    {this.state.testimonalTab === 0 &&
                        <div className="analyticContainer">
                            <img src={analyticImg.default} style={{zIndex:2, width: "646px", height: "400px", maxWidth: "100vw"}} />
                            <div className="textContainer" style={{zIndex:2, display: "flex", marginBottom: "50px", flexDirection: "column", alignItems:'flex-start', justifyContent:'space-around'}}>
                                <Typography style={{ color: "#00184C", fontSize: "48px", fontWeight: 700, textAlign:"start" }} variant="h6">Title</Typography>
                                <Typography style={{maxWidth: "550px", color: "#000000", fontSize: "20px", fontWeight: 400, textAlign:"start", marginTop: "-16px"  }} variant="h6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </Typography>
                                <Button variant="outlined" style={webStyle.learnMoreBtn as any}>Learn more</Button>
                            </div>
                        </div>
                    }

                    {this.state.testimonalTab === 1 &&
                        <div style={{zIndex:2, backgroundColor: "white", display: "flex", gap: "30px", width: "80%", flexDirection: "row", marginTop: "50px", borderRadius: "16px", overflow:"hidden",
                        }}>
                            <img className="testimonalImg" src={testimonalBg.default} style={{zIndex:2, width: "calc(min(386px, 30vw))", aspectRatio: 0.83, objectFit: "cover", }} />
                            <div style={{zIndex:2, display: "flex", padding:"20px", flexDirection: "column", alignItems:'flex-start', justifyContent:'center', gap: "30px", paddingBlock: "20px"}}>
                                <Typography style={{ color: "#00184C", fontSize: "48px", fontWeight: 700, textAlign:"start" }} variant="h6">Title</Typography>
                                <Typography style={{maxWidth: "550px", color: "#000000", fontSize: "20px", fontWeight: 400, textAlign:"start"  }} variant="h6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                            </div>
                        </div>
                    }
                </TestimonalSection>

                {/* Analytics Section */}
                <AnalyticsSection>
                    <Typography variant="h5" style={{ ...webStyle.sectionTitle, ...{ textAlign: "start", fontSize: "48px", maxWidth: "100vw"} }}>Follow us on LinkedIn<img src={linkedln.default} style={{ width: "62px", height: "62px", verticalAlign: "middle", marginLeft: "30px" }} /></Typography>
                    <Typography variant="body1" style={{ maxWidth: "100vw", textAlign: "start", width: "595px", color: "#00184C", fontWeight: 400, fontSize: "24px" }}>
                        Let's connect together
                    </Typography>
                </AnalyticsSection>

                {/* Footer Section */}
                <Footer>
                    <div>
                        <img src={logoWhite.default} style={{ width: "calc(min(254px, 30vw))", aspectRatio: 4.4, marginBlock: "120px" }} />
                    </div>
                    <div style={{ width: "100%", height: "0.7px", backgroundColor: "#FFFFFF", marginBottom: "20px" }} />
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={webStyle.footerText}>Copyright, Silenium 2024. All rights reserved.</Typography>
                        <Typography variant="h6" style={webStyle.footerText}>Follow Us: <img src={linkedlnWhite.default} style={{ width: "24px", height: "24px", verticalAlign: "middle", marginLeft: "20px" }} /></Typography>
                    </div>
                </Footer>
            </Container>
        );
    }
}

// Styled Components
const CustomTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: '#00184C',
    },
});

const CustomTab = styled(Tab)({
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: 400,
    color: '#00184C',
    '&.Mui-selected': {
        color: '#00184C',
    },
});

const Container = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#f5f5f5",
    boxSizing: "border-box",

    "*": {
        fontFamily: "Inter, sans-serif",
    }
});

const Header = styled(Box)({
    width: "100%",
    maxWidth: "100vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 40px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #e0e0e0",
    boxSizing: "border-box",
    flexWrap: "wrap",
    gap: "20px"
});

const NavLinks = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "20px",
    fontFamily: "inter",
    fontSize: "18px"
});

const HeroSection = styled(Box)({
    textAlign: "center",
    backgroundColor: "#f0f8ff",
    backgroundImage: `url(${bg.default})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    width: "100%",
    aspectRatio: 1.83,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
});

const FeaturesSection = styled(Box)({
    textAlign: "center",
    backgroundColor: "#F1F1F3",
    width: "100%",
    paddingTop: "40px",
});

const FeatureCards = styled(Box)({
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    marginTop: "30px",
    overflowX: "scroll",
    flexWrap: "wrap",
});

const FeatureCard = styled(Box)({
    position:"relative",
    padding: "20px",
    color: "#fff",
    borderRadius: "8px",
    textAlign: "center",
    background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
    width: "300px",
    height: "396px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    "@media (max-width: 1440px)": {
         width: "260px",
    },
    "@media (max-width: 1280px)": {
        width: "300px",
   },
});

const VideoSection = styled(Box)({
    textAlign: "center",
    backgroundColor: "#F1F1F3",
    width: "100%",
    aspectRatio: 1.83,
    marginTop: "50px",
    overflow: "hidden",
    marginLeft: "-2px"
});

const TestimonalSection = styled(Box)({
    position:"relative",
    boxShadow: "0px 4px 40px 0px #0000001A inset",
    textAlign: "center",
    backgroundColor: "#F1F1F3",
    width: "100%",
    paddingTop: "80px",
    overflow: "hidden",
    marginLeft: "-2px",
    paddingBottom: "100px",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    flexDirection: "column",

    "& .testimonalImg": {
        "@media (max-width: 860px)": {
            display: "none"
          },
    },

    "& .analyticContainer": {
        paddingInline: "20px",
        display: "flex", 
        flexDirection: "row",
        marginTop: "50px", 
        justifyContent: "center", 
        gap: "50px",

        "@media (max-width: 1280px)": {
            flexDirection: "column",
          },

        "& .textContainer": {
            gap: "30px",
            marginLeft: "30px"
        }
    }
});

const AnalyticsSection = styled(Box)({
    textAlign: "center",
    backgroundColor: "#fff",
    width: "100%",
    backgroundImage: `url(${linkedlnBanner.default})`,
    backgroundSize: "cover",
    backgroundPosition: "center 50px",
    aspectRatio: 3.2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "50px"
});

const Footer = styled(Box)({
    backgroundColor: "#00184C",
    height: "431px",
    width: "100%",
    padding: "20px 100px",
    boxSizing: "border-box",

    "@media (max-width: 800px)": {
        padding: "20px 50px",
    },
});

// Inline styles
const webStyle = {
    logoText: { fontWeight: 700, fontSize: "24px" },
    navLink: { cursor: "pointer", color: "#00184C", fontSize: "16px" },
    signupButton: {
        color: "#00184C", textTransform: "none",
        fontWeight: 800, fontSize: "16px", borderRadius: "22px", padding: "10px 16px",
        borderColor: "#00184C",
        width: "205px", height: "44px",
        fontFamily: "Lato, sans-serif",
    },
    learnMoreBtn: {
        color: "#00184C", 
        textTransform: "none",
        fontWeight: 700, 
        fontSize: "14px", 
        borderRadius: "22px", 
        padding: "10px 16px",
        borderColor: "#00184C",
        width: "205px", 
        height: "44px" ,
        backgroundColor: "#F1F1F3"
    },
    heroTitle: { fontWeight: 700, color: "#00184C", fontSize: "48px" },
    heroDescription: { color: "#00184C", margin: "20px 0", fontWeight: 400, fontSize: "24px" },
    demoButton: {
        background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
        width: "207px",
        height: "44px",
        padding: "10px 16px",
        gap: "8px",
        borderRadius: "24px",
        textTransform: "none",
        fontWeight: 700,
        fontSize: "14px",
        marginTop: "15px"
    },
    sectionTitle: { fontWeight: 700, color: "#00184C", fontSize: "36px" },
    sectionDescription: { color: "#00184C", marginBottom: "20px", fontWeight: 400, fontSize: "24px" },
    videoPlaceholder: {
        width: "100%",
        height: "250px",
        backgroundColor: "#c7c7c7",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        fontWeight: 700
    },
    analyticsPlaceholder: {
        width: "100%",
        height: "300px",
        backgroundColor: "#e0e0e0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#333"
    },
    footerText: { fontWeight: 400, fontSize: "12px", color: "white", opacity: 0.8 }
};

// Customizable Area End