// Customizable Area Start
import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  TextField,
  MenuItem,
  Autocomplete,
  Select,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Built-in MUI Icon
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { circleTick,back } from "./assets";
import ContactusController, { Props } from "./ContactusController";
import { linkedlnWhite, logo, logoWhite,wrong } from "./assets";
import Layout from '../../../components/src/Layout.web';
import {
  Country,
  ICountry
  //async functions
} from "country-state-city";

// Customizable Area End
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End
// Customizable Area Start
export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }
  

  validateForm = () => {
    const { formValues } = this.state;
   
    let emailValid = true;
    let phoneValid = true;

    // Email validation
    if (formValues.email) {
    
      emailValid = this.validateEmail(formValues.email);
      this.setState({ emailvalidation: !emailValid });
    } 
    if (!this.state.token && formValues.phone) {
      phoneValid = this.validatePhone(formValues.phone);
      this.setState({ mobilevalidation: !phoneValid });
    } 
    let isValid;
    // Check all fields are filled and valid
    if (this.state.token) {

      isValid =
        formValues.firstName &&
        formValues.lastName &&
        formValues.companyName &&
        this.validateEmail(formValues.email) &&
        formValues.message;
    } else {

      isValid =
        formValues.firstName &&
        formValues.lastName &&
        formValues.companyName &&
        this.validateEmail(formValues.email) &&
        formValues.country &&
        this.validatePhone(formValues.phone) &&
        formValues.topic &&
        formValues.message;
    }
    this.setState({ formIsValid: isValid });
  };
  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState(
      (prevState) => ({
        formValues: {
          ...prevState.formValues,
          [name]: value,
        },
      }),
      () => {
        this.validateForm(); // Run validation after state update
      }
    );
  };




  handleClose = () => {
    this.setState({
      open: false,
      error:false,
      formIsValid:false,
      formValues: {
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        country: '',
        phone: '',
        topic: '',
        message: '',
      },
    });
  };
    helperProps = {
      sx: {
        color: '#DC2626',
        fontSize: '14px',
        textAlign: "right",
        marginInline: 0
      },
    }

   helperLeftProps = {
      sx: {
        color: '#DC2626',
        textAlign: "left",
        fontSize: '14px',
        marginInline: 0
      },
    }

    landingPageContactUs = () =>  {
    const { formValues, required, emailvalidation } = this.state;
    const emailShouldShowErrorIcon = (required && !formValues.email) || emailvalidation;

        return <Layout navigation={this.props.navigation} currentRoute='contact us' data-test-id="testlayout_post">

              <Box sx={contactStyle.container1}>
                <Typography variant="h4" align="left" sx={{
                  fontWeight: 'bold',
                  color: '#1a1a1a', fontSize: '24px'
                }}>
                  Contact Us
                </Typography>
                <Typography variant="h4" align="left" sx={{
                  color: '#1a1a1a', fontSize: '16px'
                }}>
                  Take a look at your organization!
                </Typography>
                <Box sx={contactStyle.fieldcontainer}>
                  <Box style={contactStyle.fieldinnerContainer}>
                    <Typography sx={contactStyle.fieldtitle}>First name</Typography>
                    <TextField
                      placeholder="Enter your first name"
                      name="firstName"
                      value={formValues.firstName}
                      onChange={this.handleChange}
                      style={contactStyle.bg}
                      helperText={required && !formValues.firstName ? 'First name is required' : ''}
                      fullWidth
                      InputProps={{endAdornment: required && !formValues.firstName ? <CloseRoundedIcon style={{ color: "red" }} /> : null}}
                        FormHelperTextProps={this.helperProps}
                    />
                  </Box>
                  <Box style={contactStyle.fieldinnerContainer}>
                  <Typography sx={contactStyle.fieldtitle}>Company name</Typography>
                    <TextField
                      placeholder="Enter your company name"
                      name="companyName"
                      value={formValues.companyName}
                      onChange={this.handleChange}
                      helperText={required && !formValues.companyName ? 'Company name is required' : ''}
                      style={contactStyle.bg}
                      fullWidth
                      InputProps={{endAdornment: required && !formValues.companyName ? <CloseRoundedIcon style={{ color: "red" }} /> : null}}
                        FormHelperTextProps={this.helperProps}
                    />
                   
                  </Box>
                </Box>
                <Box sx={contactStyle.fieldcontainer}>
                  <Box style={contactStyle.fieldinnerContainer}>
                  <Typography sx={contactStyle.fieldtitle}>Last name</Typography>
                    <TextField
                      placeholder="Enter your last name"
                      name="lastName"
                      value={formValues.lastName}
                      onChange={this.handleChange}
                      helperText={required && !formValues.lastName ? 'Last name is required' : ''}
                      style={contactStyle.bg}
                      fullWidth
                      InputProps={{endAdornment: required && !formValues.lastName ? <CloseRoundedIcon style={{ color: "red" }} /> : null}}
                        FormHelperTextProps={this.helperProps}
                    />
                  </Box>


                  <Box style={contactStyle.fieldinnerContainer}>
                    <Typography sx={contactStyle.fieldtitle}>Email address</Typography>

                    <TextField
                      placeholder="Enter your email"
                      name="email"
                      type="email"
                      value={formValues.email}
                      onChange={this.handleChange}
                      style={contactStyle.bg}
                      helperText={
                        required && !formValues.email
                          ? 'Email address is required'
                          : formValues.email && emailvalidation
                            ? 'This email is not registered. Enter a valid email address.'
                            : ''
                      }
                      fullWidth
                      InputProps={{endAdornment: emailShouldShowErrorIcon ? (
    <CloseRoundedIcon style={{ color: "red" }} />
  ) : null }}
                        FormHelperTextProps={this.helperProps}
                    />
                  </Box>
                </Box>

                <Box>
                  <Typography sx={contactStyle.fieldtitle}>How can we help you?</Typography>
                  <TextField
                    placeholder="Enter your request for assistance "
                    name="message"
                    value={formValues.message}
                    onChange={this.handleChange}
                    style={contactStyle.bg}
                    helperText={required && !formValues.message ? 'Invalid format. Required field message. ' : ''}
                    multiline
                    rows={4}
                    fullWidth
                    InputProps={{endAdornment: (required && !formValues.message) ? <CloseRoundedIcon style={{ color: "red" }} /> : null }}
                        FormHelperTextProps={this.helperLeftProps}
                  />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    variant="outlined"
                    onClick={this.handleSubmit}
                    data-test-id="postsubmit"
                    sx={{
                      width: '207',
                      height: '44px',
                      border: '2px solid #00184C',
                      borderRadius: '24px',

                      textContent: 'center',
                      fontWeight: 700 as 700,
                      textTransform: "capitalize" as "capitalize",
                      backgroundColor: this.state.formIsValid ? '#00184C' : 'transparent', // Dynamic background color
                      color: this.state.formIsValid ? '#fff' : '#00184C', // Text color based on validity
                      cursor: this.state.formIsValid ? 'pointer' : 'not-allowed', // Pointer styling
                      '&:hover': {
                        backgroundColor: this.state.formIsValid ? '#00184C' : 'transparent', // Ensure consistent hover styles
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
           
           
 <Dialog open={this.state.error} style={{ zIndex: 1 }} data-test-id="presuccess">
 <Box
   width="586px"
   height="722px"
   display='flex'
   flexDirection='column'
   justifyContent='start'
   alignItems='center'
  
 >

   <Box alignItems="center" display="flex" flexDirection="row" marginBottom='26px'>
   
   <Typography style={contactStyle.logoText}>Error submitting form</Typography>
   </Box>
   <Box width='433px' display='flex'  alignItems='center' flexDirection="column" justifyContent='center' >
     <Typography style={contactStyle.logoText}>Oops, something went wrong</Typography>
      
     <Typography style={contactStyle.signupText}>An error occurred while sending your request, <br /> please try again later, we are working on it.</Typography>
     <Box style={contactStyle.circleTick}>
       <img src={wrong.default} />
     </Box>
     <Button
       style={contactStyle.goLoginbtn}
       onClick={this.handleClose}
     >
       Close
     </Button>
   </Box>
 </Box>

</Dialog>
<Dialog
             open={this.state.open} style={{ zIndex: 1 }} data-test-id="postsuccess">
                <Box
                  width="586px"
                  height="722px"
                  bgcolor="rgba(235, 235, 235, 0.898)"
                  borderRadius="16px"
                  border="3px solid white"
                  display='flex'
                  flexDirection='column'
                  justifyContent='start'
                 alignItems='center'
                >

                  <Box alignItems="left" display="flex" flexDirection="row" marginBottom='26px'>
                 
                    <Typography style={contactStyle.logoText}>Form successfully submitted</Typography>
                  </Box>
                  <Box width='433px' display='flex'  alignItems='center' flexDirection="column" justifyContent='center' >
                    <Typography style={{...contactStyle.logoText, fontSize: "30px", color: "#334155"}}>We care about you</Typography>
                    <Typography style={contactStyle.signupText}>Our team has received your request, we  <br /> will contact you soon by email to help you.</Typography>
                    <Box style={contactStyle.circleTick}>
                      <img src={circleTick.default} />
                    </Box>
                    <Button
                      style={contactStyle.goLoginbtn}
                      onClick={this.handleClose}
                    >
                      Close
                    </Button>
                  </Box>
                </Box>

              </Dialog>

            </Layout>}

  renderCountries = (selected: any) =>  selected ? selected : <span style={{ color: '#9e9e9e' }}>Select a Country</span>;

  renderTopic = (selected: any) => selected ? selected : <span style={{ color: '#9e9e9e' }}>Select a Topic</span>;
                            
  render() {
    const { formValues, required, emailvalidation, mobilevalidation } = this.state;

    return (

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>

          {(this.state.token && this.state.token != undefined) ? (
           this.landingPageContactUs()
          ) : (
            <>
              <Header>
                <img src={logo.default} style={{ width: "142px", height: "32px" }} />
                <NavLinks>
                  <Typography style={contactStyle.navLink}>About Us</Typography>
                  <Typography style={contactStyle.navLink}>Pricing</Typography>
                  <Typography style={contactStyle.navLink} sx={{ borderBottom: "4px solid #00184C" }}>Contact Us</Typography>
                </NavLinks>

                <Button data-test-id="signInBtn" onClick={this.onSignIn} variant="outlined" style={contactStyle.signupButton as any}>Signup/Login</Button>
              </Header>
              <Typography variant="h4" align="center" gutterBottom sx={{
                fontWeight: 'bold',
                marginTop: '55px',
                fontSize: "48px",
                color: "#00184C"
              }}>
                Let us tell you about our services.
              </Typography>
              {!this.state.open && !this.state.error ? (
                <Box sx={contactStyle.container} data-test-id="testlayout">

                  <Box sx={contactStyle.fieldcontainer}>
                    <Box style={contactStyle.fieldinnerContainer}>
                      <Typography sx={contactStyle.fieldtitle}>First name</Typography>

                      <TextField
                        placeholder="Enter your first name"
                        name="firstName"
                        value={formValues.firstName}
                        onChange={this.handleChange}
                        InputProps={{endAdornment: (required && !formValues.firstName) ? <CloseRoundedIcon style={{ color: "red" }} /> : null }}
                        helperText={required && !formValues.firstName ? 'First name is required' : ''}
                        fullWidth
                        FormHelperTextProps={this.helperProps}
                      />
                    </Box>
                    <Box style={contactStyle.fieldinnerContainer}>
                      <Typography sx={contactStyle.fieldtitle}>Company name</Typography>
                      <TextField
                        placeholder="Enter your company name"
                        name="companyName"
                        value={formValues.companyName}
                        onChange={this.handleChange}
                        fullWidth
                        helperText={required && !formValues.companyName ? 'Company name is required' : ''}
                        InputProps={{endAdornment: (required && !formValues.companyName) ? <CloseRoundedIcon style={{ width:"24px", height: "24px", color: "red" }} /> : null}}
                        FormHelperTextProps={this.helperProps}
                      />
                    </Box>
                  </Box>
                  <Box sx={contactStyle.fieldcontainer}>
                     <Box style={contactStyle.fieldinnerContainer}>
                      <Typography sx={contactStyle.fieldtitle}>Last Name</Typography>
                      <TextField
                        placeholder="Enter your last name"
                        name="lastName"
                        value={formValues.lastName}
                        onChange={this.handleChange}
                        helperText={required && !formValues.lastName ? 'Last name is required' : ''}
                        fullWidth
                        InputProps={{endAdornment: required && !formValues.lastName ? <CloseRoundedIcon style={{ color: "red" }} /> : null }}
                        FormHelperTextProps={this.helperProps}
                      />
                    </Box>
                    <Box style={contactStyle.fieldinnerContainer}>
                      <Typography sx={contactStyle.fieldtitle}>Email address</Typography>

                      <TextField
                        placeholder="Enter your email"
                        name="email"
                        type="email"
                        value={formValues.email}
                        onChange={this.handleChange}
                        helperText={
                          required && !formValues.email
                            ? 'Email address is required'
                            : formValues.email && emailvalidation
                              ? 'This email is not registered. Enter a valid email address.'
                              : ''
                        }
                        fullWidth
                        InputProps={{endAdornment: ((required && !formValues.email)|| emailvalidation) && <CloseRoundedIcon style={{ color: "red" }} /> }}
                        FormHelperTextProps={this.helperProps}
                      />
                    </Box>
                  </Box>
                  {this.state.token == undefined && (
                    <Box sx={contactStyle.fieldcontainer}>
                      <Box style={{ width: '22%' }}>
                        <Typography sx={contactStyle.fieldtitle}>Country</Typography>
                          <Select
                            name="country"
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={formValues.country}
                            onChange={this.handleChange}
                            IconComponent={(props) => (
                              <ExpandMoreIcon {...props} sx={{ fontSize: '1rem', color: "#64748B" }} />
                            )}
                            fullWidth
                            renderValue={this.renderCountries}
                          >
                            {Country.getAllCountries().map((item) => (
                              <MenuItem key={item.name} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>

                      </Box>
                      <Box style={{ width: '22%' }}>
                        <Typography sx={contactStyle.fieldtitle}>Phone Number</Typography>
                        <TextField
                          placeholder="Enter your phone number"
                          name="phone"
                          type="phone"
                          value={formValues.phone}
                          onChange={this.handleChange}
                          helperText={
                            required && !formValues.phone
                              ? 'Phone number is required'
                              : formValues.phone && mobilevalidation
                                ? 'Invalid format. Please enter a new number. '
                                : ''
                          }
                          fullWidth
                        InputProps={{endAdornment: ((required && !formValues.phone)|| mobilevalidation) && <CloseRoundedIcon style={{ color: "red" }} /> }}
                        FormHelperTextProps={this.helperProps}
                        />
                      </Box>
                      <Box style={{ width: '48%' }}>
                        <Typography sx={contactStyle.fieldtitle}>Select a Topic:</Typography>
                          <Select
                            name="topic"
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={formValues.topic}
                            onChange={this.handleChange}
                            IconComponent={(props) => (
                              <ExpandMoreIcon {...props} sx={{ fontSize: '1rem', color: "#64748B" }} />
                            )}
                            fullWidth
                            renderValue={this.renderTopic}
                          >
                            <MenuItem value="Billing and Finance">Billing and Finance</MenuItem>
                            <MenuItem value="Technical Support">Technical Support</MenuItem>
                            <MenuItem value="General Inquiry">General Inquiry</MenuItem>
                          </Select>
                      </Box>
                    </Box>
                  )}
                  <Box>
                    <Typography sx={contactStyle.fieldtitle}>How can we help you?</Typography>
                    <TextField
                      placeholder="Enter your request for assistance "
                      name="message"
                      value={formValues.message}
                      onChange={this.handleChange}
                      helperText={required && !formValues.message ? 'Invalid format. Required field message. ' : ''}
                      multiline
                      rows={4}
                      fullWidth
                      InputProps={{endAdornment: (required && !formValues.message) ? <CloseRoundedIcon style={{ color: "red" }} /> : null }}
                      FormHelperTextProps={this.helperLeftProps}
                    />
                  </Box>
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="outlined"
                      onClick={this.handleSubmit}
                      data-test-id="presubmit"
                      sx={{

                        width: '207',
                        height: '44px',
                        border: '2px solid #00184C',
                        borderRadius: '24px',

                        textContent: 'center',
                        fontWeight: 700 as 700,
                        textTransform: "capitalize" as "capitalize",
                        backgroundColor: this.state.formIsValid ? '#00184C' : 'transparent', // Dynamic background color
                        color: this.state.formIsValid ? '#fff' : '#00184C', // Text color based on validity
                        cursor: this.state.formIsValid ? 'pointer' : 'not-allowed', // Pointer styling
                        '&:hover': {
                          backgroundColor: this.state.formIsValid ? '#00184C' : 'transparent', // Ensure consistent hover styles
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              ) : (
<>
{this.state.error ?(
 <FormBox style={{ zIndex: 1 }} data-test-id="presuccess">
 <Box
   width="586px"
   height="722px"
   display='flex'
   flexDirection='column'
   justifyContent='start'
   alignItems='center'
 >

   
   <Box width='433px' display='flex' flexDirection="column" justifyContent='center' alignItems='center'>
     <Typography style={contactStyle.logoText}>Oops, something went wrong</Typography>
      
     <Typography style={contactStyle.signupText}>An error occurred while sending your request, <br /> please try again later, we are working on it.</Typography>
     <Box style={contactStyle.circleTick}>
       <img src={wrong.default} />
     </Box>
     <Button
       style={contactStyle.goLoginbtn}
       onClick={this.handleClose}
     >
{/* additional small change for the ticket requirement so should not to be considered as churn  */}
       Lets try again
     </Button>
   </Box>
 </Box>

</FormBox>
):(
  <FormBox style={{ zIndex: 1 }} data-test-id="presuccess">
  <Box
    width="586px"
    height="722px"
    display='flex'
    flexDirection='column'
    justifyContent='start'
    alignItems='center'
  >

    
    <Box width='433px' display='flex' flexDirection="column" justifyContent='center' alignItems='center'>
      <Typography style={contactStyle.logoText}>We care about you</Typography>
      <Typography style={contactStyle.signupText}>Our team has received your request, we  <br /> will contact you soon by email to help you.</Typography>
      <Box style={contactStyle.circleTick}>
        <img src={circleTick.default} />
      </Box>
      <Button
        style={contactStyle.goLoginbtn}
        onClick={this.handleClose}
      >
        Continue
      </Button>
    </Box>
  </Box>

</FormBox>
)}

</>
               
              )}

              <Footer>
                <div>
                  <img src={logoWhite.default} style={{ width: "calc(min(254px, 30vw))", aspectRatio: 4.4, marginBlock: "120px" }} />
                </div>
                <div style={{ width: "100%", height: "0.7px", backgroundColor: "#FFFFFF", marginBottom: "20px" }} />
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                  <Typography variant="h6" style={contactStyle.footerText}>Copyright, Silenium 2024. All rights reserved.</Typography>
                  <Typography variant="h6" style={contactStyle.footerText}>Follow Us: <img src={linkedlnWhite.default} style={{ width: "24px", height: "24px", verticalAlign: "middle", marginLeft: "20px" }} /></Typography>
                </div>
              </Footer>
            </>
          )}


        </ThemeProvider>
      </StyledEngineProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const FormBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  flex: 1,
  justifyContent: "center",

  "@media (max-height: 801px)": {
    scale: 0.95
  },
  "@media (max-height: 751px), (max-width: 751px)": {
    scale: 0.9
  },
  "@media (max-height: 701px), (max-width: 701px)": {
    scale: 0.85
  },
  "@media (max-height: 651px), (max-width: 651px)": {
    scale: 0.75
  },
});
const Footer = styled(Box)({
  backgroundColor: "#00184C",
  height: "431px",
  width: "100%",
  padding: "20px 100px",
  boxSizing: "border-box",

  "@media (max-width: 800px)": {
    padding: "20px 50px",
  },
});
const Header = styled(Box)({
  width: "100%",
  maxWidth: "100vw",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px 40px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #e0e0e0",
  boxSizing: "border-box",
  flexWrap: "wrap",
  gap: "20px"
});

const NavLinks = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  fontFamily: "inter",
  fontSize: "18px"
});
const contactStyle = {
  container: {
    margin: '20px 60px',

    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    backgroundColor: '#ffffff',

  },
  container1: {
    margin: '20px 40px',

    display: 'flex',
    flexDirection: 'column',
    gap: 3,


  },
  fieldtitle: {
    fontWeight: 'bold',
    color: '#333'

  },
  fieldcontainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  fieldinnerContainer: {
    width: '48%'
  },
  logoText: {
    fontWeight: 700,
    fontSize: '24px',
    paddingTop: '35px',
    paddingBottom: '8px',
    height: "40px",
    color: "#00184C",
    textAlign: 'center' as 'center',
    fontStyle: 'inter',
  },
  signupText: {
    color: '#334155',
    textAlign: 'center' as 'center',
    fontSize: "16px",
    fontStyle: 'inter',
    fontWeight: 400,
    lineHeight: '24px',

  },
  goLoginbtn: {
    marginTop: '40px',
    width: '207',
    height: '44px',
    border: '1px solid #A4DFFF',
    borderRadius: '24px',
    textContent: 'center',
    fontWeight: 700 as 700,
    textTransform: "none" as "none",
    backgroundColor: '#00184C',
    color: 'white',
    cursor: "pointer",
  },
  circleTick: {
    marginTop: '30px',
    marginBottom: '20px'
  },
  footerText: { fontWeight: 400, fontSize: "12px", color: "white", opacity: 0.8 },
  navLink: { cursor: "pointer", color: "#00184C", fontSize: "16px" },
  signupButton: {
    color: "#00184C", textTransform: "none",
    fontWeight: 800, fontSize: "16px", borderRadius: "22px", padding: "10px 16px",
    borderColor: "#00184C",
    width: "205px", height: "44px",
    fontFamily: "Lato, sans-serif",
  },
  bg: {
    background: '#fff'
  }
};

// Customizable Area End
