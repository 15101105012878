import React from "react";
// Customizable Area Start
import {
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Box,
  Typography,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Stack,
  TableBody,
  TableCell,
  Alert,
  Snackbar,
  Pagination,
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
  Modal,
  Container,
  TextareaAutosize,
  Grid,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Slider,
  Switch,
} from "@mui/material";
const DatePicker = require("react-datepicker");
import Layout from '../../../components/src/Layout.web';
import { ic_back, ic_plus, ic_success, ic_success_delete } from "../../../blocks/accountgroups/src/assets";
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import "react-datepicker/dist/react-datepicker.css";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { icThinDropdown, infoIcon } from "./assets";
import {
  ELargeModalType,
  EModalType,
} from "./CustomisableUserProfilesController";
import { styled } from "@mui/styles";
import {
  Theme,
} from "@mui/material/styles";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    ) {
      return "number";
    }
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    ) {
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.value,
                field.field_type
              )
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true,
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );
    }

    if (field.field_type === "date" || field.field_type === "datetime") {
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(
                  this.state.profile[field.name] as string
                ).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date: Date) =>
                this.changeFormValue(field.name, date, field.field_type)
              }
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );
    }

    if (field.field_type === "checkbox") {
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.checked,
                field.field_type
              )
            }
            color="primary"
          />
          {field.title}
        </div>
      );
    }
    if (field.field_type === "boolean") {
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(
                  field.name,
                  event.target.value,
                  field.field_type
                );
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="True"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </div>
      );
    }
  };

 CopyBotContent = () =>   <Container style={{ maxWidth: "unset", overflow: 'auto', display: "flex", flexDirection: "column", height: "100%", padding: "30px 70px"  }}>
                <div style={{ display: "flex", gap: "20px", paddingLeft: "60px", paddingBottom: "30px"}}>
                  <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
                    <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0 }}>Copy from existing bot</Typography>
                    <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "16px" }}>Copy the bot configuration of your choice.</Typography>
                  </div>
                <IconButton className="filter-button" aria-label="filter" style={{gap: "10px", borderBottom: "1px solid #C4C4C6", borderRadius: 0, height: "38px"}}>
                  <FilterListIcon style={{ color: '#0F172A' }} />
                  <span style={{ color: "#75809B", fontSize: "14px", fontWeight: 700 }}>Filters</span>
                </IconButton>
                <Box
                  sx={{
                    borderBottom: "1px solid #C4C4C6",   
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '0px',
                    padding: '4px 8px',
                    width: '250px',
                    height: "38px",
                    boxSizing: "border-box",
                  }}
                >
                  <SearchIcon sx={{ color: '#0F172A', marginRight: '8px' }} />
                  <TextField
                    style={{ height: "100%" }}
                    placeholder="Search"
                    size="small"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        fontSize: '14px',
                        height: "100%",
                        fontFamily: "Inter, sans-serif",
                        color: '#00184C',
                        fontWeight: 700,
                      },
                    }}
                    fullWidth
                  />
                </Box>
                </div>

              <Paper sx={{ display: "flex", flex: 1, padding: 0, boxShadow: "0px 2px 8px 0px #00000014", marginTop: "10px" }}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ overflow: "hidden" }}>
                      <StyledTableCell >Bot Name</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Campaign Assignment</StyledTableCell>
                      <StyledTableCell>Linked User</StyledTableCell>
                      <StyledTableCell style={{ opacity: 0, borderRadius: 0 }}>Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.botList.slice((this.state.currentPage - 1) * 12, (this.state.currentPage - 1) * 12 + 12).map((group: any) => (
                      <TableRow key={group.id} hover>
                        <StyledTableCell style={{ textDecoration: "underline" }}>{group.attributes.name}</StyledTableCell>
                        <StyledTableCell style={{
                          alignItems: "center",
                          display: "inline-flex",
                          justifyContent: "center",
                          width: "100%"
                        }}>
                          <StatusButton>
                            Completed
                          </StatusButton>
                        </StyledTableCell>
                        <StyledTableCell style={{ textDecoration: "underline" }}>{group.attributes.campaign_assignment}</StyledTableCell>
                        <StyledTableCell>{group.linked_user}</StyledTableCell>
                  
                        <StyledTableCell>
                          <CopyBotBtn data-test-id="copyBotBtn"  onClick={() => this.handleCopyBot(group)()}>Copy this bot</CopyBotBtn>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{ marginTop: 2, marginBottom: "30px",display: "flex", flex: 3 }}
          >
            <div style={{ height: "1px", width: "120px" }} />
            {/* Pagination */}
            <StyledPagination
              count={Math.ceil(this.state.botList.length / 12)}
              page={this.state.currentPage}
              shape="rounded"
              siblingCount={0}
              boundaryCount={1}
              onChange={this.handlePageChange}
            />

            {/* Results Summary */}
            <ResultsSummary>
              {this.getResultsSummary()}
            </ResultsSummary>
          </Stack>
       
          </Container>

 HotLeadContent = () =>   <Container style={{ maxWidth: "unset", overflow: 'auto', display: "flex", flexDirection: "column", height: "100%", padding: "10px"  }}>
                <div style={{ display: "flex", gap: "20px", paddingLeft: "60px", paddingBottom: "30px"}}>
                  <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
       <div style={{ display: "flex", flexDirection: "row", gap: "25px" }}>
         <div>
           <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
             <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0 }}>{this.state.botInfo?.attributes?.name}</Typography>
             <StatusButton style={{ fontSize: "12px", borderRadius: "40px", height: "32px" }}>
               Active
             </StatusButton>
           </div>
           <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "16px" }}><span style={{ fontWeight: 700, fontSize: "16px" }}>Bot email:</span> {this.state.botInfo?.attributes?.bot_email}</Typography>
         </div>
         <Button
           style={{
             background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
             color: "#FFFFFF",
             padding: "10px 16px",
             borderRadius: "26px",
             fontSize: "14px",
             lineHeight: "20px",
             display: "flex",
             alignItems: "center",
             fontWeight: "700",
             gap: "8px",
             textTransform: "capitalize",
             whiteSpace: "nowrap",
             height: '44px',
           }}
           variant="contained"
           color="primary"
         >
           Edit Bot Profile
         </Button>
       </div>                
                <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "16px", marginTop: "8px" }}><span style={{fontWeight: 700, fontSize: "16px"}}>Campaign Assignment:</span> <span style={{ textDecoration: "underline" }}>Campaign Name</span></Typography>
                  </div>

                <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", flexDirection: "row", gap: "25px", justifyContent:"flex-end"}}>
                  <IconButton className="filter-button" aria-label="filter" style={{gap: "10px", borderBottom: "1px solid #C4C4C6", borderRadius: 0, height: "38px"}}>
                    <FilterListIcon style={{ color: '#0F172A' }} />
                    <span style={{ color: "#75809B", fontSize: "14px", fontWeight: 700 }}>Filters</span>
                  </IconButton>
                  <Box
                    sx={{
                      borderBottom: "1px solid #C4C4C6",   
                      display: 'flex',
                      width: '250px',
                      height: "38px",
                      boxSizing: "border-box",
                      alignItems: 'center',
                      borderRadius: '0px',
                      padding: '4px 8px',
                    }}
                  >
                    <SearchIcon sx={{ color: '#0F172A', marginRight: '8px' }} />
                    <TextField
                      style={{ height: "100%" }}
                      placeholder="Search"
                      size="small"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          fontSize: '14px',
                          fontFamily: "Inter, sans-serif",
                          height: "100%",
                          fontWeight: 700,
                          color: '#00184C',
                        },
                      }}
                      fullWidth
                    />
                  </Box>
                </div>
       <div style={{
         display: "flex",
         flexDirection: "row",
         alignItems: "center",
         justifyContent: "flex-end",
       }}>
         <Box
           display="flex"
           alignItems="center"
           sx={{
             maxWidth: "294px",
             backgroundColor: '#E9E9EA',
             padding: '8px 16px',
             borderRadius: '22px',
             boxShadow: '0px 2px 8px 0px #00000014',
             marginTop: "25px",
             gap:"20px"
           }}
         >
           <Typography variant="body2" sx={{ color: '#00184C', fontSize: "11px" }}>
             <span style={{ fontSize: "12px", fontWeight: 700 }}>Toggle option</span> shows the hot leads<br />that are already completed
           </Typography>
           <StyledSwitch></StyledSwitch>
         </Box>
       </div>
                </div>
                </div>

              <Paper sx={{ display: "flex", flex: 1, padding: 0, boxShadow: "0px 2px 8px 0px #00000014", marginTop: "10px" }}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ overflow: "hidden" }}>
                      <StyledTableCell >Hot Lead Name</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Hot Lead Data Trigger</StyledTableCell>
                      <StyledTableCell>Hot Lead Primary Contact</StyledTableCell>
                      <StyledTableCell style={{ opacity: 0, borderRadius: 0 }}>Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          <Stack
            alignItems="flex-end"
            justifyContent="space-between"
            direction="row"
            sx={{ marginTop: 2, display: "flex", flex: 3,  marginBottom: "30px", }}
          >
            <div style={{  width: "120px" , height: "1px"}} />
            {/* Pagination */}
            <StyledPagination
              count={Math.ceil(this.state.botList.length / 12)}
              siblingCount={0}
              boundaryCount={1}
              page={this.state.currentPage}
              shape="rounded"
              onChange={this.handlePageChange}
            />
            <ResultsSummary>
              {this.getResultsSummary()}
            </ResultsSummary>

          </Stack>
          </Container>

  EditBotContent = () => <>
  {(this.state.modalType === EModalType.CONFIRM_EDIT_BOT || this.state.modalType === EModalType.SUCCESS_EDIT_BOT || this.isTest) && 
  <>
    <div style={{display: "flex", flexDirection: "column"}}>
    <Typography style={{fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0}}>Edit User Profile</Typography>
    </div>

    {this.changesContent()}
   {this.state.modalType === EModalType.SUCCESS_EDIT_BOT && <img src={ic_success.default} style={{alignSelf:"center", width: "150px", aspectRatio: 1.05}} />}
    {this.ButtonGroup()}
  </>
  }
  </>

    ButtonGroup = () => 
      <StyledButtonGroup style={{justifyContent: this.state.modalType === EModalType.CONFIRM_EDIT_BOT ? "space-between" : "center"}}>
      {this.state.modalType === EModalType.CONFIRM_EDIT_BOT ?
        <>
          <Button className='cancel-btn' variant="outlined" onClick={this.handleCloseConfirmModal}>
            Cancel
          </Button>
          <Button className='create-btn' variant="contained" color="primary" onClick={() => this.updateBot()}>
            Update User
          </Button>
        </>
        : <Button style={{alignSelf:"center"}} className='create-btn' variant="contained" color="primary" onClick={this.handleCloseConfirmModal}>
            Continue
        </Button>
      }
  </StyledButtonGroup>

changeContentAddition = () => <>
 {(this.state.signature !== this.state.botInfo?.attributes?.signature || this.isTest) && 
    <>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Signature:</Typography>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>{`${this.state.botInfo?.attributes?.signature} >`} <b>{this.state?.signature}</b></Typography>
    </>
    }

  {(this.state.botResponseType !== this.state.botInfo?.attributes?.bot_response_type || this.isTest) && 
    <>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Bot Response Type:</Typography>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>{`${this.state.botInfo?.attributes?.bot_response_type} >`} <b>{this.state?.botResponseType}</b></Typography>
    </>
    }

  {(this.state.botKnowledge !== this.state.botInfo?.attributes?.bot_knowledge || this.isTest) && 
    <>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Bot Knowledge:</Typography>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>{`${this.state.botInfo?.attributes?.bot_knowledge} >`} <b>{this.state?.botKnowledge}</b></Typography>
    </>
    }
</>

  changesContent = () => {
  return <>
     <div style={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      gap: "10px",
      maxHeight: "300px", 
      overflowY: "auto",
    }}>
    <Typography style={{textAlign:"center", fontSize: "30px", fontWeight: 700, color: "#00184C", margin: 0, width: "320px"}}>{this.state.modalType === EModalType.CONFIRM_EDIT_BOT ? "Are you sure to make these changes" : "You made these changes"}</Typography>
    {(this.state.botName !== this.state.botInfo?.attributes?.name || this.isTest) && 
    <>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Bot Name:</Typography>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>{`${this.state.botInfo?.attributes?.name} >`} <b>{this.state?.botName}</b></Typography>
    </>
    }

    {(this.state.botEmail !== this.state.botInfo?.attributes?.bot_email || this.isTest) && 
    <>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Bot Email:</Typography>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>{`${this.state.botInfo?.attributes?.bot_email} >`} <b>{this.state?.botEmail}</b></Typography>
    </>
    }

    {this.state.professionalism !== this.state.botInfo?.attributes?.professionalism && 
    <>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Professionalism:</Typography>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>{`${this.state.botInfo?.attributes?.professionalism} >`} <b>{this.state?.professionalism}</b></Typography>
    </>
    }

  {(this.state.expressiveness !== this.state.botInfo?.attributes?.expressiveness || this.isTest) && 
    <>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Expressiveness:</Typography>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>{`${this.state.botInfo?.attributes?.expressiveness} >`} <b>{this.state?.expressiveness}</b></Typography>
    </>
    }

  {(this.state.assertiveness !== this.state.botInfo?.attributes?.assertiveness || this.isTest) && 
    <>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Assertiveness:</Typography>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>{`${this.state.botInfo?.attributes?.assertiveness} >`} <b>{this.state?.assertiveness}</b></Typography>
    </>
    }

  {(this.state.emailSignOff !== this.state.botInfo?.attributes?.email_sign_offs || this.isTest) && 
    <>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Email Sign Offs:</Typography>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>{`${this.state.botInfo?.attributes?.email_sign_offs} >`} <b>{this.state?.emailSignOff}</b></Typography>
    </>
    }

    {(this.state.LOFEmail !== this.state.botInfo?.attributes?.length_of_follow_up_emails || this.isTest) && 
    <>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Email Sign Offs:</Typography>
      <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>{`${this.state.botInfo?.attributes?.length_of_follow_up_emails} >`} <b>{this.state?.LOFEmail}</b></Typography>
    </>
    }

    {this.changeContentAddition()}
    
    </div>
    </>
}
  render() {
    // Customizable Area Start
    const selected: any[] = []
  
    const BotCloseContent = () => <>
    {(this.state.modalType === EModalType.CONFIRM_CLOSE) && 
  <>
       <div style={{display: "flex", flexDirection: "column"}}>
       <Typography style={{fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0}}>Cancel edit form</Typography>
       </div>

      <div style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}>
       <Typography style={{textAlign:"center", fontSize: "30px", fontWeight: 700, color: "#00184C", margin: 0, width: "462px"}}>You want to forget the changes made?</Typography>
       <Typography style={{textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>You have not saved the changes you have made. Do you want to continue?</Typography>
       <img src={ic_success_delete.default} style={{width: "150px", marginTop: "75px", aspectRatio: 0.97}} />

       </div>
           <StyledButtonGroup>
                <Button className='cancel-btn' variant="outlined" onClick={this.handleCloseConfirmModal}>
                    Cancel
                </Button>
                <Button className='delete-btn' variant="contained" color="primary" onClick={() => this.handleCloseConfirmModal()}>
                    Forget the changes
                </Button>
       </StyledButtonGroup>
  </>
  }
  </>

    return (
      <Layout navigation={this.props.navigation} currentRoute='Bots'>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyledContainer>
            {/* Header Section */}
            <Box className="header-container">
              <img src={ic_back.default} style={{ width: "28px", height: "28px", position: "absolute", top: "30px" }} />
              {/* Title and Description */}
              <Box style={{ display: "flex", flexDirection: "column", marginLeft: "80px" }}>
                <Typography variant="h5" fontWeight="bold" style={{
                  color: "#00184C",
                  fontSize: "24px",
                }}>
                  Bots List
                </Typography>
                <Typography style={{
                  color: "#00184C",
                  fontSize: "16px",
                  fontWeight: "400"
                }} variant="body2" color="textSecondary">
                  This is the list of bots assigned to you.
                </Typography>
              </Box>
              {/* Actions (Add User, Filters, Search) */}
              <Box className="header-top">
                <Button
                  style={{
                    background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
                    color: "#FFFFFF",
                    padding: "10px 16px",
                    borderRadius: "26px",
                    fontSize: "16px",
                    lineHeight: "20px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "700",
                    gap: "8px",
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => this.onAddBot()}
                >
                  <img src={ic_plus.default} style={{ width: "24px", height: "24px" }} />
                  New Bot
                </Button>
                <IconButton className="filter-button" aria-label="filter">
                  <FilterListIcon style={{ color: '#0F172A' }} />
                  <span style={{ color: "#75809B", fontSize: "14px", fontWeight: 700 }}>Filters</span>
                </IconButton>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #E2E8F0',
                    borderRadius: '0px',
                    padding: '4px 8px',
                    width: '250px',
                    backgroundColor: "white",
                    height: "38px",
                    boxSizing: "border-box",
                    borderBottom: "1px solid #C4C4C6",
                  }}
                >
                  <SearchIcon sx={{ color: '#0F172A', marginRight: '8px' }} />
                  <TextField
                    style={{ height: "100%" }}
                    placeholder="Search"
                    size="small"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        fontSize: '14px',
                        height: "100%",
                        fontFamily: "Inter, sans-serif",
                        color: '#00184C',
                        fontWeight: 700,
                      },
                    }}
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
              <div style={{ display: "flex", flexDirection: "row", gap: "35px", marginLeft: "80px", marginBottom: "15px" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '36px', height: '36px', boxShadow: '0px 2px 8px 0px #00000014', borderRadius: '50%', backgroundColor: '#F1F1F3' }}>
                    <img src={infoIcon} alt="info icon" />
                  </div>
                  <Typography style={{ fontSize: "18px", fontWeight: 700, color: "#00184C" }}># Of bots your company have access</Typography>
                  <Typography style={{ fontSize: "18px", fontWeight: 400, color: "#00184C" }}>3</Typography>
                </div>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px"  }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '36px', height: '36px', boxShadow: '0px 2px 8px 0px #00000014', borderRadius: '50%', backgroundColor: '#F1F1F3' }}>
                    <img src={infoIcon} alt="info icon" />
                  </div>
                  <Typography style={{ fontSize: "18px", fontWeight: 700, color: "#00184C" }}>Bots Currently Assigned</Typography>
                  <Typography style={{ fontSize: "18px", fontWeight: 400, color: "#00184C" }}>2</Typography>
                </div>
              </div>
            {/* Table Section */}
            <Paper sx={{ display: "flex", flex: 1, padding: 0, boxShadow: "0px 2px 8px 0px #00000014", marginTop: "10px" }}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ overflow: "hidden" }}>
                      <StyledTableCell >Bot Name</StyledTableCell>
                      <StyledTableCell>Campaign Assignment</StyledTableCell>
                      <StyledTableCell>Linked User</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell style={{ opacity: 0, borderRadius: 0 }}>Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.botList.slice((this.state.currentPage - 1) * 12, (this.state.currentPage - 1) * 12 + 12).map((group: any) => (
                      <TableRow key={group.id} hover>
                        <StyledTableCell onClick={() => this.onOpenHotLead(group)} style={{ cursor: "pointer", textDecoration: "underline" }}>{group.attributes.name}</StyledTableCell>
                        <StyledTableCell style={{ textDecoration: "underline" }}>{group.attributes.campaign_assignment}</StyledTableCell>
                        <StyledTableCell>{group.linked_user}</StyledTableCell>
                        <StyledTableCell style={{
                          alignItems: "center",
                          display: "inline-flex",
                          justifyContent: "center",
                          width: "100%"
                        }}>
                          <StatusButton>
                            Active
                          </StatusButton>
                        </StyledTableCell>
                        <StyledTableCell>
                          <EditButton onClick={() => this.handleEdit(group)()}>Edit</EditButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </StyledContainer>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
            sx={{ marginTop: 2, height: "100%", marginBottom: "40px" }}
          >
            <div style={{ width: "120px", height: "1px" }} />
            {/* Pagination */}
            <StyledPagination
              count={Math.ceil(this.state.botList.length / 12)}
              page={this.state.currentPage}
              shape="rounded"
              siblingCount={0}
              boundaryCount={1}
              onChange={this.handlePageChange}
            />

            {/* Results Summary */}
            <ResultsSummary>
              {this.getResultsSummary()}
            </ResultsSummary>
          </Stack>

          <Snackbar
            open={this.state.isToastOpen}
            autoHideDuration={5000}
            onClose={this.handleCloseToast}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={this.handleCloseToast}
              icon={false}
              style={{
                backgroundColor: "#E6FAF0",
                color: "#000000",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                borderLeft: "4px solid #4CAF50",
              }}
              action={
                <IconButton size="small" aria-label="close" onClick={this.handleCloseToast}>
                  <CloseRoundedIcon fontSize="small" />
                </IconButton>
              }
            >
              <div contentEditable='true' dangerouslySetInnerHTML={{ __html: this.state.toastContent }}></div>
            </Alert>
          </Snackbar>

          <Modal
            slotProps={{
              backdrop: {
                sx: {
                  backgroundColor: '#00184CB2',
                  backdropFilter: 'blur(10px)', // Blur effect
                  transition: 'all 0.5s ease', // Smooth animation
                  marginLeft: "234px"
                },
              },
            }}
            open={this.state.isVisibleModal} onClose={this.handleCloseModal}>
            <StyledBox>
              {(this.state.largeModalType === ELargeModalType.INITIAL || this.isTest) &&
              <Container style={{ overflow: 'auto', padding: "30px 70px", maxWidth: "unset" }}>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div>
                    <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0 }}>{this.state.isEdit ? "Edit Bot Profile" : "Customize User Profile"}</Typography>
                    <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "16px" }}>Your profile can have personality.</Typography>
                    <Typography className="requireField" style={{ color: '#00184C', fontWeight: 700, fontSize: "16px" }}>
                      <span>
                        Linked User:
                      </span>
                      <span style={{ fontWeight: 400 }}>
                        {' botemail#1@silenium.com'}
                      </span>
                    </Typography>

                  </div>
                  <Button
                    style={{
                      background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
                      color: "#FFFFFF",
                      padding: "10px 16px",
                      borderRadius: "26px",
                      fontSize: "16px",
                      lineHeight: "20px",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "700",
                      gap: "8px",
                      textTransform: "capitalize",
                      whiteSpace: "nowrap",
                      height: '44px',
                    }}
                    variant="contained"
                    color="primary"
                    onClick={this.handleCopyBotModal}
                  >
                    Copy from Existing Bot
                  </Button>
                </div>
                <Box style={{ marginTop: '40px' }}>
                  <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0 }}>Basic Information</Typography>
                  <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={5} columnSpacing={5} gap={6}>
                      <Grid item lg={6} style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      paddingRight: "40px"
                    }}>
                        <Box>
                          <Typography style={styles.labelStyle}>Bot Name</Typography>
                          <TextField
                            type="text"
                            placeholder="Enter bot name"
                            fullWidth
                            value={this.state.botName}
                            onChange={this.onChangeBotName}
                            style={styles.textInput}
                          />
                        </Box>
                        <Box style={{ marginTop: '40px' }}>
                          <Typography style={styles.labelStyle}>Professionalism</Typography>
                          <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "14px", marginTop: '10px' }}>This field will determine how professional or casual the bot’s response are.</Typography>
                        </Box>
                        <Box style={{ marginBlock: '10px', display: "flex" }}>
                        <CustomizableSlider steps={this.professionalismSteps} activeStep={this.professionalismSteps.findIndex(e => e === this.state.professionalism)} onStepChange={(e) => this.setState({professionalism: this.professionalismSteps[e]})} />
                        </Box>
                        <Box style={{ marginTop: '40px' }}>
                          <Typography style={styles.labelStyle}>Assertiveness</Typography>
                          <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "14px", marginTop: '10px' }}>This will determine how hard the bot pushes prospects into setting up a meeting.</Typography>
                        </Box>
                        <Box style={{ marginBlock: '10px', display: "flex" }}>
                        <CustomizableSlider rightLabelStyle={{whiteSpace: "nowrap"}} leftLabelStyle={{whiteSpace: "nowrap"}} steps={this.assertivenessStep} activeStep={this.assertivenessStep.findIndex(e => e === this.state.assertiveness)} onStepChange={(e) => this.setState({assertiveness: this.assertivenessStep[e]})} />
                        </Box>
                      </Grid>
                      <Grid item lg={6} style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%"
                    }}>
                        <Box>
                          <Typography style={styles.labelStyle}>Bot Email</Typography>
                          <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <TextField
                              type="email"
                              placeholder="Enter bot Email"
                              fullWidth
                              value={this.state.botEmail}
                              onChange={this.onChangeBotEmail}
                              style={{ ...styles.textInput, flex: '1' }}
                            />
                            <Typography style={{ ...styles.labelStyle, height: 'fit-content' }}>@silenium.com</Typography>
                            <StyledHtmlTooltip  
                            placement="bottom-end"
                              title={
                                <React.Fragment>
                                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginBottom: "5px"}}>
                                      <img src={infoIcon} alt="info icon" />
                                      <Typography color="inherit" style={{fontWeight: 700}}>Bot Email</Typography>
                                  </div>
                                  <Typography color="inherit" style={{lineHeight: "22px", fontSize: "14px"}}>
                                    Domain Restriction: The domain (e.g., @silenium.com) should be automatically set by the system and is only able to be overridden by the system administrators; the domain will not be configurable on the bot page.
                                    <br />
                                    <br />
                                    Domain restriction clarification:
                                    <br />
                                    [ bot ] @example.com
                                    <br />
                                    [ bot ] is what the user enters
                                  </Typography>
                                </React.Fragment>
                              }
                            >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '36px', height: '36px', boxShadow: "0px 2px 8px 0px #00000014", borderRadius: '50%', backgroundColor: '#F1F1F3' }}>
                              <img src={infoIcon} alt="info icon" />
                            </div>
                            </StyledHtmlTooltip>
                          </Box>
                        </Box>
                        <Box style={{ marginTop: '40px' }}>
                          <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Typography style={styles.labelStyle}>Expressiveness</Typography>
                            <StyledLeftHtmlTooltip
                              placement="bottom-start"
                              title={
                                <React.Fragment>
                                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginBottom: "5px"}}>
                                      <img src={infoIcon} alt="info icon" />
                                      <Typography color="inherit" style={{fontWeight: 700}}>Expressiveness</Typography>
                                  </div>
                                  <Typography color="inherit" style={{lineHeight: "22px", fontSize: "14px"}}>
                                  This will determine how expressive the bot is. For example, a highly expressive bot may use exclamation points, emojis, etc.
                                  </Typography>
                                </React.Fragment>
                              }
                            >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '36px', height: '36px', boxShadow: '0px 2px 8px 0px #00000014', borderRadius: '50%', backgroundColor: '#F1F1F3' }}>
                              <img src={infoIcon} alt="info icon" />
                            </div>
                            </StyledLeftHtmlTooltip>
                          </Box>
                          <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "14px", marginTop: '10px' }}>This will determine how expressive the bot is. For Example, a highly expressive bot may use exclamation points, emojis, etc.</Typography>
                          <Box style={{ marginBlock: '10px', display: "flex" }}>
                          <CustomizableSlider steps={this.expressivenessSteps} activeStep={this.expressivenessSteps.findIndex(e => e === this.state.expressiveness)} onStepChange={(e) => this.setState({expressiveness: this.expressivenessSteps[e]})} />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box style={{ marginTop: '40px' }}>
                  <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0 }}>Communication Settings</Typography>
                  <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={5}  columnSpacing={5} gap={6}>
                      <Grid item lg={6} style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      paddingRight: "40px"
                    }}>
                        <Box>
                          <Typography style={styles.labelStyle}>Email Sign Offs</Typography>
                          <TextField
                            type="text"
                            placeholder="Enter sign off message"
                            fullWidth
                            style={styles.textInput}
                            value={this.state.emailSignOff}
                            onChange={this.onChangeEmailSignOff}
                          />
                          <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "14px", marginTop: '15px' }}>Define email conclusion format <br /> (e.g. “Sincerely, [Bot name]”)</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={6} style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                    }}>
                        <Box>
                          <Typography style={styles.labelStyle}>Length of Followup Emails</Typography>
                          <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "14px", marginTop: '10px' }}>Determine how long the bot’s email responses are.</Typography>
                          <Box style={{ marginBlock: '10px', display: "flex" }}>
                          <CustomizableSlider steps={this.lengthOfFollowupEmailsSteps} activeStep={this.lengthOfFollowupEmailsSteps.findIndex(e => e === this.state.LOFEmail)} onStepChange={(e) => this.setState({LOFEmail: this.lengthOfFollowupEmailsSteps[e]})} />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box style={{ marginTop: '20px' }}>
                  <div style={{display: "flex", flexDirection: 'row', gap: "15px", alignItems:"center"}}>
                    <Typography style={styles.labelStyle}>Signature</Typography>
                    <StyledLeftHtmlTooltip
                             placement="bottom-start"
                              title={
                                <React.Fragment>
                                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginBottom: "5px"}}>
                                      <img src={infoIcon} alt="info icon" />
                                      <Typography color="inherit" style={{fontWeight: 700}}>Basic Text Formatting</Typography>
                                  </div>
                                  <Typography color="inherit" style={{lineHeight: "22px", fontSize: "14px"}}>
                                  Company information display                                    </Typography>
                                </React.Fragment>
                              }
                            >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '36px', height: '36px', boxShadow: '0px 2px 8px 0px #00000014', borderRadius: '50%', backgroundColor: '#F1F1F3' }}>
                              <img src={infoIcon} alt="info icon" />
                            </div>
                    </StyledLeftHtmlTooltip>
                  </div>
                  <TextareaAutosize placeholder="Enter your message" style={{padding: "20px"}} className="textarea" value={this.state.signature} onChange={this.onChangeSignature}/>
                </Box>
                <Box style={{ marginTop: '40px', paddingRight: "40px" }}>
                  <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0 }}>Response Behavior</Typography>
                  <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={5}>
                      <Grid item lg={6}>
                        <Box>
                          <Typography style={styles.labelStyle}>Bot Response Type</Typography>
                          <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "14px", marginTop: '10px' }}>This will determine what happens when a prospect responds to a bot’s email asking questions.</Typography>
                          <Box style={{ marginBlock: '10px', display: "flex" }}>
                          <CustomizableSlider rightLabelStyle={{right: "-15px", textAlign:"center", width: "76px"}} steps={this.botResponseTypeStep} activeStep={this.botResponseTypeStep.findIndex(e => e === this.state.botResponseType)} onStepChange={(e) => this.setState({botResponseType: this.botResponseTypeStep[e]})} />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: '30px' }}>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{display: "flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
                      <Typography style={styles.labelStyle}>Bot Knowledge</Typography>
                      <StyledLeftHtmlTooltip
                              placement="bottom-start"
                              title={
                                <React.Fragment>
                                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginBottom: "5px"}}>
                                      <img src={infoIcon} alt="info icon" />
                                      <Typography color="inherit" style={{fontWeight: 700}}>Basic Text Formatting</Typography>
                                  </div>
                                  <Typography color="inherit" style={{lineHeight: "22px", fontSize: "14px"}}>
                                  To provide information that the bot should know when attempting to respond to questions.                                    </Typography>
                                </React.Fragment>
                              }
                            >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '36px', height: '36px', boxShadow: '0px 2px 8px 0px #00000014', borderRadius: '50%', backgroundColor: '#F1F1F3' }}>
                              <img src={infoIcon} alt="info icon" />
                            </div>
                    </StyledLeftHtmlTooltip>
                      </div>
                      <Typography style={{ fontSize: '12px', color: '#334155', lineHeight: '18px' }}>*Required when Attempt to answer is selected in Bot Response Type.</Typography>
                    </Box>
                    <div style={{position:"relative"}}>
                      <TextareaAutosize placeholder="Enter your message" style={{padding: "20px"}} className="textarea" value={this.state.botKnowledge}
                              onChange={this.onChangeBotKnowledge} 
                              maxLength={3500}
                        />
                        <Typography style={{
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                          fontSize: "12px",
                          fontWeight: 400,
                          textAlign: "right",
                          fontFamily: "Inter",
                          color: "#CBD5E1",
                        }}>{this.state.botKnowledge?.length ?? 0} - 3500 Character limit</Typography>
                      </div>
                  </Box>
                </Box>
                <Box style={{ marginTop: '40px' }}>
                  <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0 }}>Campaign Management</Typography>
                  <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={5}>
                      <Grid item lg={6}>
                        <Box>
                          <Typography style={styles.labelStyle}>Campaign Assignment</Typography>
                          <Typography style={{ fontSize: '14px', color: '#334155', lineHeight: '17px', margin: '15px 0' }}>Configurable via bot profile or campaign</Typography>
                            <div style={{boxSizing: "border-box", justifyContent:"space-between", display:"flex", flexDirection:"row", alignItems:"center", padding: "15px", maxWidth: "360px", height: " 56px", borderRadius: "8px", border: "1px solid #CBD5E1", backgroundColor: "white"}}>
                              <Typography style={{fontWeight: 700, fontSize: "14px", color: "#334155"}}>Selecting a campaign name</Typography>
                              <img src={icThinDropdown} style={{width: "16px", height: "16px"}} />
                            </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box style={{ margin: '40px 0', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px', color: '#00184C' }}>
                  <Button sx={{ ...styles.button, borderColor: '#00184C' }} variant="outlined" onClick={() => this.setState({isVisibleModal: false, isConfirmModalVisible: true, modalType: EModalType.CONFIRM_CLOSE})}>Cancel</Button>
                  <Button data-test-id="saveChangeBtn" sx={{ ...styles.button, backgroundColor: '#00184C', color: 'white' }} variant="contained" onClick={() => this.state.isEdit ? this.setState({isConfirmModalVisible: true, isVisibleModal: false, modalType: EModalType.CONFIRM_EDIT_BOT}) : this.createBot()}>Save Changes</Button>
                </Box>
              </Container>}
              {(this.state.largeModalType === ELargeModalType.COPY_BOT || this.isTest) &&
                this.CopyBotContent()
                }
              {(this.state.largeModalType === ELargeModalType.HOT_LEAD || this.isTest) &&
                this.HotLeadContent()
                }
              <img data-test-id="closeBtn" onClick={() => this.state.largeModalType === ELargeModalType.INITIAL ? this.handleCloseModal() : this.setState({largeModalType: ELargeModalType.INITIAL})} src={ic_back.default} style={{zIndex: 1, cursor: "pointer", width: "28px", height: "28px", position: "absolute", top: "20px", left: "15px" }} />
            </StyledBox>
          </Modal>
          <Modal
            slotProps={{
              backdrop: {
                sx: {
                  backgroundColor: '#00184CB2',
                  backdropFilter: 'blur(10px)', // Blur effect
                  transition: 'all 0.5s ease', // Smooth animation
                  marginLeft: "234px"
                },
              },
            }}
            open={this.state.isConfirmModalVisible} onClose={this.handleCloseModal}>
            <ConfirmModalBox>

              {BotCloseContent()}
              {this.EditBotContent()}

              <img onClick={this.onModalBack} src={ic_back.default} style={{ cursor: "pointer", width: "28px", height: "28px", position: "absolute", top: "20px", left: "15px" }} />
            </ConfirmModalBox>
          </Modal>
        </ThemeProvider>
      </StyledEngineProvider>



    </Layout>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledButtonGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(4),
  gap: "20px",
  marginBottom: "20px",

  "@media (max-height: 720px)": {
    gap: "15px",
    marginBottom: "15px",
    marginTop: theme.spacing(2),
  },
}));

const StyledHtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white, // LightTooltip background
    color: '#334155', // LightTooltip text color
    boxShadow: "0px 25px 50px 0px #00000017",
    fontSize: "14px", // LightTooltip font-size
    borderRadius: "16px 0px 16px 16px",
    width: "400px",
    padding: "15px"
  },
}));

const StyledLeftHtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: '#334155', 
    backgroundColor: theme.palette.common.white,
    fontSize: "14px",
    borderRadius: "0px 16px 16px 16px",
    width: "400px",
    boxShadow: "0px 25px 50px 0px #00000017",
    padding: "15px"
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: 'calc(50% + 234px / 2)', // Adjusting for the sidebar width
  transform: 'translate(-50%, -50%)',
  width: "calc(90% - 235px)",
  boxShadow: theme.shadows[5],
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  background: "linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(235, 235, 235, 0.9) 100%)",
  border: "3px solid #FFFFFFE5",
  borderRadius: "16px",
  boxSizing: "border-box",
  height: "90vh",
  padding: "10px",

  "@media (max-height: 720px)": {
    minHeight: "unset",
    scale: 0.85,
    marginTop: "-50px",
    marginLeft: "-32px",
    padding: "20px",
  },

  "& .cancel-btn": {
    border: "1px solid #00184C",
    flex: 1,
    display: "flex",
    textTransform: "capitalize",
    color: "#00184C",
    borderRadius: "24px",
    fontWeight: 700,
    height: "44px",
    maxWidth: "210px"
  },

  "& .create-btn": {
    display: "flex",
    flex: 1,
    borderRadius: "24px",
    border: "1px solid #A4DFFF",
    background: " #00184C",
    textTransform: "capitalize",
    color: "white",
    fontWeight: 700,
    height: "44px",
    maxWidth: "210px"
  },

  "& .update-btn": {
    border: "1px solid #A4DFFF",
    background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
    display: "flex",
    flex: 1,
    textTransform: "capitalize",
    color: "white",
    borderRadius: "24px",
    height: "44px",
    maxWidth: "210px",
    fontWeight: 700,
  },

  "& .delete-btn": {
    border: "1px solid #7F1D1D",
    display: "flex",
    color: "white",
    height: "44px",
    background: "linear-gradient(122.97deg, #FFA0A0 -55.47%, #4C0000 40.61%)",
    flex: 1,
    borderRadius: "24px",
    textTransform: "capitalize",
    fontWeight: 700,
    maxWidth: "210px"
  },

  "& .textField": {
    border: "1px solid #CBD5E1",
    backgroundColor: "white",
    borderRadius: "8px",
    marginTop: "10px",

    "@media (max-height: 720px)": {
      "& input": { padding: "12px 14px" }
    },
  },

  "& .textArea": {
    border: "1px solid #CBD5E1",
    backgroundColor: "white",
    borderRadius: "8px",
    marginTop: "10px",
    width: "100%",
    minHeight: "156px",

    "&::placeholder": {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      textAlign: "left",
      color: "#64748B"
    },

    "@media (max-height: 720px)": {
      "& input": { padding: "12px 14px" }
    },
  },
}));

const ConfirmModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  left: 'calc(50% + 234px / 2)',
  width: "580px",
  boxShadow: theme.shadows[5],
  border: "3px solid #FFFFFFE5",
  padding: "25px",
  display: 'flex',
  flexDirection: 'column',
  background: "linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(235, 235, 235, 0.9) 100%)",
  borderRadius: "16px",
  paddingInline: "60px",
  gap: theme.spacing(2),
  boxSizing: "border-box",
  minHeight: "650px",
  justifyContent: "space-between",

  "@media (max-height: 720px)": {
    minHeight: "unset",
    padding: "20px",
    paddingInline: "60px",
    scale: 0.85,
    marginTop: "-50px",
    marginLeft: "-32px",
  },

  "& .cancel-btn": {
    border: "1px solid #00184C",
    display: "flex",
    textTransform: "capitalize",
    color: "#00184C",
    height: "44px",
    flex: 1,
    borderRadius: "24px",
    fontWeight: 700,
    maxWidth: "210px"
  },

  "& .create-btn": {
    border: "1px solid #A4DFFF",
    display: "flex",
    borderRadius: "24px",
    fontWeight: 700,
    textTransform: "capitalize",
    color: "white",
    flex: 1,
    background:" #00184C",
    height: "44px",
    maxWidth: "210px"
  },

  "& .update-btn": {
    border: "1px solid #A4DFFF",
    textTransform: "capitalize",
    color: "white",
    height: "44px",
    display: "flex",
    flex: 1,
    borderRadius: "24px",
    background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
    fontWeight: 700,
    maxWidth: "210px"
  },

  "& .delete-btn": {
    border: "1px solid #7F1D1D",
    display: "flex",
    flex: 1,
    background: "linear-gradient(122.97deg, #FFA0A0 -55.47%, #4C0000 40.61%)",
    fontWeight: 700,
    maxWidth: "210px",
    borderRadius: "24px",
    color: "white",
    height: "44px",
    textTransform: "none",
  },
}));

const StatusButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#D1FAE5",
  color: "#059669",
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
  "&:hover": {
    backgroundColor: "#c5f0d0",
  },
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 60,
  height: 34,
  padding: 0,
  display: 'inline-flex',

  '& .MuiSwitch-switchBase': {
    padding: 4,
    '&.Mui-checked': {
      transform: 'translateX(26px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#059669',
        opacity: 1,
        border: 0,
      },
    },
  },

  '& .MuiSwitch-thumb': {
    width: 26,
    height: 26,
    backgroundColor: '#fff',
  },

  '& .MuiSwitch-track': {
    borderRadius: 34 / 2,
    backgroundColor: '#475569',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 400,
    }),
  },
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPagination-ul": {
    justifyContent: "center", // Align pagination to the left
  },
  "& .MuiPaginationItem-root": {
    color: "#00184C",
    fontWeight: 400,
    "&.Mui-selected": {
      color: "#00184C",
      borderRadius: "50%",
      backgroundColor: "transparent",
      fontWeight: 700,
    },
  },
}));

const ResultsSummary = styled(Typography)(({ theme }) => ({
  color: 'grey',
  fontSize: "14px",
}));

const CopyBotBtn = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  border: "1px solid #00184C",
  padding: "4px 8px",
  color: "#00184C",
  borderRadius: "8px",
  fontWeight: "bold",
  textTransform: "none",

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const EditButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  backgroundColor: "#fff",
  color: "#00184C",
  border: "1px solid #00184C",
  borderRadius: "8px",
  padding: "4px 8px",
  fontWeight: "bold",
  width: "75px",

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: "white",
  color: "#22223E",
  textAlign: "center",
  borderBottom: "none",
  borderTop: "1px solid #E9E9EA",
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "*": { fontFamily: "Inter, sans-serif" },

  "& *": {
    boxSizing: "border-box",
    fontFamily: "Inter, sans-serif"
  },
  '& .header-container': {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: "20px",
    justifyContent: "space-between",
    backgroundColor: "#E9E9EA",
    marginTop: "20px",
  },
  '& .header-top': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: "20px",
    flexDirection: "row",
    gap: "20px"
  },
  '& .header-actions': {
    display: 'flex',
    alignItems: 'center',
    gap: "20px",
  },
  '& .table-container': {
    maxHeight: 600,
    overflow: 'hidden',
  },
  '& .filter-button': {
    padding: "10px",
    width: "96px",
    height: "38px",
    borderBottom: "1px solid #C4C4C6",
    backgroundColor: "white",
    borderRadius: 0,
    gap: "10px"
  },
}));
const styles = {
  labelStyle: {
    color: '#334155',
    fontSize: '14px',
    fontWeight: 700 as 700,
    paddingBottom: '4px',
    lineHeight: '22px',
    marginTop: "15px",
    paddingHorizontal: "20px"
  },
  boxStyle: {
    display: "flex",
    flexDirection: "column" as "column"
  },
  textInput: {
    border: "1px solid #CBD5E1",
    backgroundColor: "white",
    borderRadius: "8px"
  },
  button: {
    height: '44px',
    width: '207px',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '700',
    color: '#00184C',
    lineHeight: '22px',
    textTransform: "capitalize",
  }
}

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: 'transparent',
  height: 6,
  padding: '30px 0',
  '& .MuiSlider-track': {
    border: 'none',
    background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
    height: 4,
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#94A3B8',
    height: 3,
    opacity: 1,
  },
  '& .MuiSlider-mark': {
    height: 12,
    width: 12,
    borderRadius: '50%',
    backgroundColor: '#94A3B8',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  '& .MuiSlider-markActive': {
    height: 12,
    width: 12,
    borderRadius: '50%',
    background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
    transform: 'translateX(-50%) translateY(-50%)',
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    background: '#F6D8FF',
    height: 28, // Adjusted to 28x28 for active step
    width: 28,
    border: '2px solid #00184C',
    borderRadius: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    opacity: 1,
  },
  '& .MuiSlider-markLabel': {
    color: "#334155",
    fontWeight: 700,
    fontSize: "14px",
    marginTop: "20px",
    maxWidth: "68px",
    whiteSpace: "break-spaces",
  },
}));


interface CustomizedSliderProps {
  steps: string[];
  activeStep: number;
  onStepChange: (step: number) => void;
  leftLabelStyle?: any;
  rightLabelStyle?: any;
}

const CustomizableSlider: React.FC<CustomizedSliderProps> = ({ steps, activeStep, onStepChange, leftLabelStyle, rightLabelStyle }) => {
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      onStepChange(newValue);
    }
  };

const getMarkLabel = (e: any, i: any, steps: any) => {
  if (i === 0) {
    return <span style={{ position: "absolute", left: "-10px", ...leftLabelStyle }}>{e}</span>;
  } else if (i === steps.length - 1) {
    return <span style={{ position: "absolute", right: "-10px", ...rightLabelStyle }}>{e}</span>;
  } else {
    return e;
  }
};

  return (
    <Stack sx={{ width: '100%', paddingInline: "10px" }} spacing={4}>
      <StyledSlider
        value={activeStep}
        min={0}
        max={steps.length - 1}
        step={1}
        marks={steps.map((e, i) => ({
          value: i,
          label: getMarkLabel(e, i, steps),
        }))}
        onChange={handleSliderChange}
      />
    </Stack>
  );
};
// Customizable Area End
