import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Account, Group, GroupAccount } from "./types";
import { getStorageData } from "framework/src/Utilities";

export enum EModalType {
  INITIAL,
  CONFIRM_CREATE_USER,
  SUCCESS_CREATE_USER,
  CONFIRM_DELETE_USER,
  SUCCESS_DELETE_USER,
  CONFIRM_EDIT_USER,
  SUCCESS_EDIT_USER,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  name: string;
  createdAt: string;
  updatedAt: string;
  editMode: boolean;
  token: string;
  groupList: Group[];
  isVisibleModal: boolean;
  isVisibleAddAccountModal: boolean;
  isVisibleDeleteAccountModal: boolean;
  accountsData: Account[];
  modalAccData: Account[];
  selectedAccounts: GroupAccount[];
  dropdownAccountStatus: boolean;
  fieldError: boolean;
  editInfo: any;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  modalType: EModalType;
  firstNameError: string;
  lastNameError: string;
  emailError: string;
  roleError: string;
  currentPage: number;
  isToastOpen: boolean;
  toastContent: string;
  searchText: string;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupsApiCallId = "";
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  putGroupApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  updateUserApiCallId = "";
  resendApiCallId = "";
  isTest = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "0",
      name: "",
      createdAt: "",
      updatedAt: "",
      editMode: false,
      token: "",
      groupList: [],
      isVisibleModal: false,
      isVisibleAddAccountModal: false,
      isVisibleDeleteAccountModal: false,
      accountsData: [],
      modalAccData: [],
      dropdownAccountStatus: false,
      selectedAccounts: [],
      fieldError: false,
      editInfo: undefined,
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      modalType: EModalType.INITIAL,
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      roleError: "",
      currentPage: 1,
      isToastOpen: false,
      toastContent: "",
      searchText: ""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  }

  private debounceTimeout: NodeJS.Timeout | null = null;

  componentDidUpdate(prevProps: Props, prevState: S) {
    // Check if searchText has changed
    if (prevState.searchText !== this.state.searchText) {
      // Clear any existing timeout to prevent multiple triggers
      if (this.debounceTimeout || this.isTest) {
        clearTimeout(this.debounceTimeout as any);
      }
  
      // Set a new timeout to delay execution of addGroup
      this.debounceTimeout = setTimeout(() => {
        this.getGroups(this.state.token, this.state.searchText);
      }, 500); // 500ms delay
    }
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    // Condition for fetching group list.
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getGroupsApiCallId != null &&
      this.getGroupsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.setState({ groupList:  responseJson.data });

      } else {
        this.setState({ groupList: [] });

        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    // Condition for creating new group.
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postGroupApiCallId != null &&
      this.postGroupApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getGroups(this.state.token);
        this.setState({ isVisibleModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Received", message);

      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      getStorageData("token").then((token) => {
        this.setState({ token: token });
        this.getGroups(token);
      })
    }

    // Condition for updating group details.
    this.updateGroup(message)

    // Condition for deleting group.
    this.deleteGroupAccount(message);

    // Condition for fetching user account list
    this.fetchUserAccount(message);
    
    //EDIT
    this.handleEditAccount(message);

    // Condition for adding account to the group
    this.handleAddAccount(message);

    // Condition for removing account to the group
    this.handleDeleteAccount(message);
  };

  updateGroup = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.putGroupApiCallId != null &&
      this.putGroupApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getGroups(this.state.token);
        this.setState({ isVisibleModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  deleteGroupAccount = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteGroupApiCallId != null &&
      this.deleteGroupApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.message) {
        this.getGroups(this.state.token);
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  fetchUserAccount = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAccountsApiCallId != null &&
      this.getAccountsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        const data = responseJson.data.map((account: Account) => {
          return { ...account, isSelected: false };
        });
        this.setState({
          accountsData: data,
        });
      } else {
        this.setState({ accountsData: [] });
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleEditAccount = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.updateUserApiCallId != null &&
      this.updateUserApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors && responseJson) {
        this.getGroups(this.state.token);
        this.setState({ modalType: EModalType.SUCCESS_EDIT_USER })
      }

    }
  }

  handleAddAccount = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postAddAccountsApiCallId != null &&
      this.postAddAccountsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson) {
        this.getGroups(this.state.token);
        this.setState({ modalType: EModalType.SUCCESS_CREATE_USER })
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleDeleteAccount = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postRemoveAccountsApiCallId != null &&
      this.postRemoveAccountsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson) {
        this.getGroups(this.state.token);
        this.setState({modalType: EModalType.SUCCESS_DELETE_USER})
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  hideAddAccountModal = () => {
    this.setState({ isVisibleAddAccountModal: false });
  };

  hideDeleteAccountModal = () => {
    this.setState({ isVisibleDeleteAccountModal: false });
  };

  showAddModal = () => {
    this.setState({
      name: "",
      editMode: false,
      fieldError: false,
      isVisibleModal: !this.state.isVisibleModal,
      editInfo: undefined
    });
  };

  handleInputName = (name: string) => {
    this.setState({ name, fieldError: false });
  };

  showHideAddAccountModal = () => {
    this.setState({
      isVisibleAddAccountModal: !this.state.isVisibleAddAccountModal,
      editMode: true,
    });
  };

  handleAddAccounts = (group: Group) => {
    let accArr: any[] = [];
    if (group.attributes.accounts.length > 0) {
      accArr = this.state.accountsData.filter(
        (ar) =>
          !group.attributes.accounts.find((rm) => rm.id.toString() === ar.id)
      );
    } else {
      accArr = this.state.accountsData;
    }

    this.setState({
      id: group.id,
      name: group.attributes.name,
      modalAccData: accArr,
    });
    this.showHideAddAccountModal();
  };

  showHideDeleteAccountModal = () => {
    this.setState({
      isVisibleDeleteAccountModal: !this.state.isVisibleDeleteAccountModal,
      editMode: true,
    });
  };

  handleDeleteAccounts = (group: Group) => {
    const accData = group.attributes.accounts.map((account: GroupAccount) => {
      return { ...account, isSelected: false };
    });
    this.setState({
      id: group.id,
      name: group.attributes.name,
      selectedAccounts: accData,
    });
    this.showHideDeleteAccountModal();
  };

  handleEditGroup = (group: Group) => {
    this.setState({ id: group.id, name: group.attributes.name });
    this.showEditModal();
  };

  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  expandAccountsView = () => {
    this.setState({ dropdownAccountStatus: !this.state.dropdownAccountStatus });
  };

  handleAccountSelect = (accountId: string) => {
    const newData = this.state.modalAccData.map((account: Account) => {
      if (account.id === accountId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ modalAccData: newData });
  };

  handleDeleteAccountSelect = (groupAccountId: number) => {
    const newData = this.state.selectedAccounts.map((account: GroupAccount) => {
      if (account.id === groupAccountId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ selectedAccounts: newData });
  };

  handleGetGroups = () => {
    this.getGroups(this.state.token);
    this.getAccounts(this.state.token);
  };

  // Function to fetch the group list from API
  getGroups = (token: string, text = "") => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupsApiEndPoint}?search=${text}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to add new group and send it to API
  addGroup = () => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.setState({ fieldError: true });
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        name: this.state.name,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.groupsApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to edit group and send it to API
  editGroup = (groupId: string) => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.setState({ fieldError: true });
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        name: this.state.name,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.groupsApiEndPoint + "/" + `${groupId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to delete group and send it to API
  deleteGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to fetch the accounts list from API
  getAccounts = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksAccountsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to save accounts to the group and send it to API
  handleSaveAccountsToGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const accountIds: string[] = [];
    this.state.modalAccData.map((account: Account) => {
      if (account.isSelected) {
        accountIds.push(account.id);
      }
    });
    const httpBody = {
      account_ids: accountIds,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAddAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}` + "/add_accounts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to remove accounts from the group and send it to API
  handleRemoveAccountsToGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const accountIds: string[] = [];
    this.state.selectedAccounts.map((account: GroupAccount) => {
      if (account.isSelected) {
        accountIds.push(account.id.toString());
      }
    });
    const httpBody = {
      account_ids: accountIds,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postRemoveAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}` + "/remove_accounts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditInfo = (user: any) => {
    this.setState({editMode: true, isVisibleModal: true, firstName: user.attributes?.first_name, lastName: user.attributes?.last_name, email: user.attributes?.email, role: user.attributes?.role, editInfo: user })
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value, emailError: "" });
  };

  handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstName: event.target.value, firstNameError: "" });
  };

  handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastName: event.target.value, lastNameError: "" });
  };

  handleRoleChange = (roleInfo: string) => {
    this.setState({ role: roleInfo, roleError: "" });
  };

  handleCloseModal = () => {
    this.setState({
      editMode: false,
      isVisibleModal: false,
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      modalType: EModalType.INITIAL,
      editInfo: undefined,
      emailError: "",
      firstNameError: "",
      lastNameError: "",
      roleError: ""
    })
  }

  createEmployee = (isUpdate = false) => () => {
    let isError = false;
    if (!this.state.firstName.trim()) {
      isError = true;
      this.setState({
        firstNameError: "This field is required."
      })
    }
    if (!this.state.lastName.trim()) {
      isError = true;
      this.setState({
        lastNameError: "This field is required."
      })
    }
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (this.state.email.trim() && !regex.test(this.state.email)) {
      isError = true;
      this.setState({
        emailError: "This email is invalid. Please enter a valid email address."
      })
    }

    if (!this.state.email.trim()) {
      isError = true;
      this.setState({
        emailError: "This field is required."
      })
    }

    if (!this.state.role.trim()) {
      isError = true;
      this.setState({
        roleError: "No option was selected, Please select a user role."
      })
    }
    if (isError) return;

    if (isUpdate) {
      this.setState({modalType: EModalType.CONFIRM_EDIT_USER});
      return;
    }

    this.setState({modalType: EModalType.CONFIRM_CREATE_USER})
  }

  handleCreateUser = () => {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };

      const httpBody = {
        "first_name": this.state.firstName, 
        "last_name": this.state.lastName,
        "email": this.state.email,
        "role": this.state.role
        };
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.postAddAccountsApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createUserApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteUser = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postRemoveAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteUserApiEndPoint + `?id=${this.state.editInfo?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUpdateUser = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const httpBody = {
      "first_name": this.state.firstName, 
      "last_name": this.state.lastName,
      "email": this.state.email,
      "role": this.state.role
      };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateUserApiEndPoint + `?id=${this.state.editInfo?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

sendInvitationToast = (name: string) => {
  this.setState({isToastOpen: true, toastContent: `You have successfully sent the invitation to the user <b>${name}</b>` })
}

resendInvitation = (id: string, name: string) => () => {
  const header = {
    "Content-Type": configJSON.apiContentType,
    token: this.state.token,
  };

  const httpBody = {
    };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.resendApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.resendInvitationApiEndPoint + `?id=${id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

  this.sendInvitationToast(name);
}

onModalBack = () => {
  if (this.state.modalType === EModalType.INITIAL || this.state.modalType === EModalType.SUCCESS_CREATE_USER || this.state.modalType === EModalType.SUCCESS_EDIT_USER || this.state.modalType === EModalType.SUCCESS_DELETE_USER) {
    this.handleCloseModal()
  }
  if (this.state.modalType === EModalType.CONFIRM_CREATE_USER || this.state.modalType === EModalType.CONFIRM_DELETE_USER || this.state.modalType === EModalType.CONFIRM_EDIT_USER) {
    this.setState({modalType: EModalType.INITIAL})
  }
  }

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({currentPage: value})
  };

  handleSearchText = (event: any) => {
    this.setState({searchText: event.target.value})
  }

  getResultsSummary = () => {
    const itemsPerPage = 12;
    const totalItems = this.state.groupList.length ?? 0; // Total results
    const startIndex = (this.state.currentPage - 1) * itemsPerPage + 1; // Start index of current page
    const endIndex = Math.min(this.state.currentPage * itemsPerPage, totalItems); // End index of current page
    return `${totalItems ? startIndex : 0} - ${endIndex} of ${totalItems} ${totalItems > 1 ? "results" : "result"}`;
  };
  
  handleCloseToast = () => {
    this.setState({isToastOpen: false, toastContent: ""})
  }
  // Customizable Area End
}
